import React, { useState, useEffect } from 'react';
import './Admin.css';
import { FaEdit, FaTrash } from 'react-icons/fa';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MultiSelectDropdown from './Multiselectdropdown';
import '@fortawesome/fontawesome-free/css/all.min.css';

const RBACPage = () => {
  const [roles, setRoles] = useState([]);
  const [regulators, setRegulators] = useState([]); 
  const [acls, setAcls] = useState([]); 
  const [error, setError] = useState(false);
  const [isAddRoleOpen, setIsAddRoleOpen] = useState(false);
  const [isAddRegulatorOpen, setIsAddRegulatorOpen] = useState(false); 
  const [isAddACLOpen, setIsAddACLOpen] = useState(false); 
  const [currentView, setCurrentView] = useState('Roles');
  const [userList, setUserList] = useState([]); 
  const [selectedLOBs, setSelectedLOBs] = useState([]); 
  const [rolesList, setRolesList] = useState([]);
  const [regulatorsList, setRegulatorsList] = useState([]);
  const [users, setUsers] = useState([]);
  const [superAdmin, setSuperAdmin] = useState([]);
  const [roleTitle, setRoleTitle] = useState('');
  const [roleDescription, setRoleDescription] = useState('');
  const [selectedOperations, setSelectedOperations] = useState([]);
  const [AdminFormLoading, setAdminFormLoading] = useState(false);
  const [selectedRole, setSelectedRole] = useState(null); 
const [isEditMode, setIsEditMode] = useState(false);    
const [selectedRegulator, setSelectedRegulator] = useState(null);
const [regulatorTitle, setRegulatorTitle] = useState('');
const [regulatorDescription, setRegulatorDescription] = useState('');
const [lob, setLob] = useState('');
const [selectedACL, setSelectedACL] = useState(null); 
  const [username, setUsername] = useState('');
  const [role, setRole] = useState('');
  const [regulator, setRegulator] = useState('');
  const [isAddUserOpen, setIsAddUserOpen] = useState(false);
  const [isAddSuperAdminOpen, setIsAddSuperAdminOpen] = useState(false);
  const [superAdminEmail , setSuperAdminEmail] = useState('');
const [userType, setUserType] = useState('add'); 
const [firstName, setFirstName] = useState('');
const [lastName, setLastName] = useState('');
const [email, setEmail] = useState('');
const [selectedUserId, setSelectedUserId] = useState(null);
const [isUserProfileDropdownOpen, setIsUserProfileDropdownOpen] = useState(false);
const [isDarkMode, setIsDarkMode] = useState(false);
const [token, setToken] = useState(null);
const [operationsList, setOperationsList] = useState([]);
const [selectedRegulators, setSelectedRegulators] = useState([]);
const [searchTerm, setSearchTerm] = useState(''); 
const LOB = ['LOB 1', 'LOB 2', 'LOB 3', 'LOB 4', 'LOB 5'];
// useEffect(() => {

//   fetch('/users_list') 
//   .then(response => response.json())
//   .then(data => {
//     const formattedUserList = data.map((email, index) => ({
//       id: email, 
//       name: email 
//     }));
//     setUserList(formattedUserList);
//   })
//   .catch(err => console.error(err));

//   fetch('rbac/get_roles') 
//     .then(response => response.json())
//     .then(data => setRolesList(data))
//     .catch(err => console.error(err));

//   fetch('rbac/get_regulators') 
//     .then(response => response.json())
//     .then(data => setRegulatorsList(data))
//     .catch(err => console.error(err));
// }, []);

  // useEffect(() => {
  //   fetchData();
  // }, [currentView]);
  const fetchUsers = async () => {
    try {
      const response = await fetch('/users_list');
      const data = await response.json();
      const formattedUserList = data.map((email) => ({
        id: email,
        name: email,
      }));
      // console.log(formattedUserList)
      setUserList(formattedUserList);
    } catch (err) {
      console.error("Error fetching users:", err);
    }
  };
  
  const fetchRoles = async () => {
    try {
      const response = await fetch('rbac/get_roles');
      const data = await response.json();
      setRolesList(data);
    } catch (err) {
      console.error("Error fetching roles:", err);
    }
  };
  
  const fetchRegulators = async () => {
    try {
      const response = await fetch('rbac/get_regulators');
      const data = await response.json(); // This is an array of objects
  
      // Extract only the regulator values
      const regulatorsOnly = data.map(item => item.regulator);
  
      // Set "RBI" as the default selected regulator if it exists in the list
      if (regulatorsOnly.includes('RBI')) {
        setSelectedRegulators(['RBI']);
      }
  
      setRegulatorsList(regulatorsOnly); // Set the state to the array of regulators
    } catch (err) {
      console.error("Error fetching regulators:", err);
    }
  };
  
const fetchOperations = async () => {
  try {
    const response = await fetch('/rbac/get_operations');
    const data = await response.json();
    const modifiedData = data.map(operation => 
      operation === "action" ? "checklist" : operation
    );

    // console.log(modifiedData);
    setOperationsList(modifiedData); 
  } catch (err) {
    console.error("Error fetching operations:", err);
  }
};

  const handleSearch = (value) => {
    setSearchTerm(value.toLowerCase());
  };

  useEffect(() => {
    setSearchTerm('');
    const fetchInitialData = async () => {
      await Promise.all([
        fetchUsers(), 
        fetchRoles(), 
        fetchOperations(),
        fetchRegulators()
      ]);
    };
  
    fetchInitialData(); 
  
    fetchData(); 
  }, [currentView]);
  
  const fetchData = async () => {
    setAdminFormLoading(true);
    try {
        const endpoint = getEndpoint(currentView);
        
        if (currentView === 'Roles') {
            // await fetchOperations(); 
        }

        const response = await fetch(endpoint);
        const data = await response.json();

        if (!data.length) {
            setError(true);
            return;
        }

        switch (currentView) {
            case 'Roles':
                setRoles(data);
                break;
            case 'Regulators':
                setRegulators(data);
                break;
            case 'ACL':
                setAcls(data);
                break;
            case 'User Management':
                setUsers(data);
                break;
            case 'Super Admins':
                setSuperAdmin(data);
                break;
            default:
                setRoles(data); 
                break;
        }
        setError(false);
    } catch (error) {
        console.error(error);
        setError(true);
    }
    setAdminFormLoading(false);
};

  const getEndpoint = (view) => {
    switch (view) {
      case 'Roles': return 'rbac/get_roles';
      case 'Regulators': return 'rbac/get_regulators';
      case 'ACL': return 'rbac/get_acl_rules';
      case 'User Management': return '/users_details';
      case 'Super Admins': return '/rbac/get_super_admins';
      default: return 'rbac/get_roles';
    }
  };
  const fetchUserList = async () => {
    try {
      const response = await fetch('/users_list');
      const data = await response.json();
      setUserList(data);
    } catch (error) {
      console.error('Failed to fetch user list:', error);
    }
  };
  const handleAddRoleClick = () => {
    setRoleTitle('');
    setIsEditMode(false); 
    setRoleDescription('');
    setSelectedOperations([]);
    setIsAddRoleOpen(true);
    setIsAddRegulatorOpen(false); 
    setIsAddACLOpen(false);
  };

  const handleAddRegulatorClick = () => {
    setRegulatorTitle('');
    setIsEditMode(false); 
    setRegulatorDescription('');
    setLob([]);
    setSelectedLOBs([]);
    setIsAddRegulatorOpen(true);
    setIsAddRoleOpen(false); 
    setIsAddACLOpen(false);
  };

  const handleAddACLClick = () => {
    setUsername('');
    setIsEditMode(false); 
    setRole('');
    setLob([]);
    setSelectedRegulators([]);
    setRegulator('');
    setIsAddACLOpen(true);
    setIsAddRoleOpen(false); 
    setIsAddRegulatorOpen(false);
  };

  const handleCloseAddRole = () => {
    setIsAddRoleOpen(false);
  };

  const handleCloseAddRegulator = () => {
    setIsAddRegulatorOpen(false);
    setSelectedLOBs([]);
  };
  const handleRegulatorSelect = (regulator) => {
    if (!selectedRegulators.includes(regulator)) {
      setSelectedRegulators([...selectedRegulators, regulator]);
    }
  };
  
  const handleCloseAddACL = () => {
    setIsAddACLOpen(false);
    setIsEditMode(false);
    setUsername('');
    setRole('');
    setRegulator('');
    setSelectedACL(null);
  };

  const handleLOBSelect = (selectedOptions) => {
    // console.log('Selected LOBs:', selectedOptions);  
    setSelectedLOBs(selectedOptions);
  };
  
  const handleRemoveRegulator = (regulator) => {
    setSelectedRegulators(selectedRegulators.filter(item => item !== regulator));
  };
  
  const Chip = ({ label, onRemove }) => (
    <div className="chip">
      {label}
      <span className="remove-chip" onClick={onRemove}>
        &times; 
      </span>
    </div>
  );

  const handleRemoveLOB = (lobToRemove) => {
    setSelectedLOBs(selectedLOBs.filter((lob) => lob !== lobToRemove));
  };
  const handleOperationsSelect = (selectedOperation) => {
    if (selectedOperation && !selectedOperations.includes(selectedOperation)) {
      setSelectedOperations([...selectedOperations, selectedOperation]);
    }
  };

  const handleRemoveOperation = (operationToRemove) => {
    setSelectedOperations(selectedOperations.filter(op => op !== operationToRemove));
  };
  
  const handleAddUser = () => {
    handleCloseAddUser(); 
  };

  const handleAddUserClick = () => {
    setFirstName('');
    setLastName('');
    setEmail('');
    setIsEditMode(false);
    setUserType('addUser');
    setIsAddUserOpen(true);
};

const handleSuperAdminClick = () => {
  setSuperAdminEmail('');
  setIsEditMode(false);
  setIsAddSuperAdminOpen(true);
};

const handleInviteUserClick = () => {
    setEmail('');
    setIsEditMode(false);
    setUserType('inviteUser');
    setIsAddUserOpen(true);
};

const handleEditUser = (user) => {
    setFirstName(user.firstname);
    setLastName(user.lastname)
    setEmail(user.user_name);
    setIsEditMode(true);
    setSelectedUserId(user.user_id);
    setIsAddUserOpen(true);
};
const handleEditSuperAdmin = (superadmin) => {
  setSuperAdminEmail(superadmin.username);
  setIsEditMode(true);
  setIsAddSuperAdminOpen(true);
};

const handleCloseAddUser = () => {
    setIsAddUserOpen(false);
    setFirstName('');
    setLastName('');
    setEmail('');
    setSelectedUserId(null);
    setIsEditMode(false);
};

const handleCloseAddSuperAdmin =() =>{
  setIsAddSuperAdminOpen(false);
  setSuperAdminEmail('')
}
const handleAddOrEditUser = async () => {

  if (!email || !role || selectedRegulators.length === 0) {
    toast.error("Please provide the email for the invite.");
    return;
  }

  setIsAddUserOpen(false);
  setAdminFormLoading(true);
  const formattedRegulators = selectedRegulators.join(',');
  try {
    const url = isEditMode ? '/edit_user' : '/invite_user';
    const payload = isEditMode
      ? {
          email_id: email,
          user_id: selectedUserId ,
          role: role,
          regulators: formattedRegulators,  
        }
      : {
          email_id: email,
          role: role,
          regulators: formattedRegulators,  

        };
    const method = 'POST';

    const response = await fetch(url, {
      method,
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(payload),
    });

    const responseData = await response.json(); 

    if (response.ok) {
      if (responseData.status === "existing_user") {
        toast.error("User already exists.");
      } else {
        handleCloseAddUser();
        fetchData(); 
        toast.success(isEditMode ? 'User edited successfully!' : 'User invited successfully!');
      }
    } else {
      toast.error(isEditMode ? 'User not edited. Please try again.' : 'User not invited. Please try again.');
    }
  } catch (error) {
    toast.error(isEditMode ? 'User not edited. Please try again.' : 'User not invited. Please try again.');
  } finally {
    setAdminFormLoading(false);
  }
};


const handleOperationChange = (e) => {
  const { value } = e.target;
  setSelectedOperations((prevOperations) =>
    prevOperations.includes(value)
      ? prevOperations.filter((operation) => operation !== value)
      : [...prevOperations, value]
  );
};

  
  const handleDeleteRole = async (roleId) => {
    setAdminFormLoading(true)
    try {
        const response = await fetch(`/rbac/delete_role`, {
            method: 'POST', 
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ role_id: roleId }),
        });

        if (response.ok) {
            toast.success('Role deleted successfully!');
            fetchData(); 
        } else {
            toast.error('Role not deleted. Please try again.');
        }
    } catch (error) {
        toast.error('Role not deleted. Please try again.');
    }
    setAdminFormLoading(false)
};

const handleDeleteRegulator = async (regulatorId) => {
  setAdminFormLoading(true)
  try {
      const response = await fetch(`/rbac/delete_regulator`, {
          method: 'POST', 
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ regulator_id: regulatorId }),
      });

      if (response.ok) {
          toast.success('Regulator deleted successfully!');
          fetchData(); 
      } else {
          toast.error('Regulator not deleted. Please try again.');
      }
  } catch (error) {
      toast.error('Regulator not deleted. Please try again.');
  }
  setAdminFormLoading(false)
};
const handleDeleteAcl = async (aclId) => {
  setAdminFormLoading(true)
  try {
      const response = await fetch(`/rbac/delete_acl_rule`, {
          method: 'POST', 
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ acl_id: aclId }),
      });

      if (response.ok) {
          toast.success('ACL deleted successfully!');
          fetchData(); 
      } else {
          toast.error('ACL not deleted. Please try again.');
      }
  } catch (error) {
      toast.error('ACL not deleted. Please try again.');
  }
  setAdminFormLoading(false)
};

const handleDeleteUser = async (userId) => {
  setAdminFormLoading(true)
  try {
      const response = await fetch(`/delete_user`, {
          method: 'POST', 
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ user_id: userId }),
      });

      if (response.ok) {
          toast.success('User deleted successfully!');
          fetchData(); 
      } else {
          toast.error('User not deleted. Please try again.');
      }
  } catch (error) {
      toast.error('User not deleted. Please try again.');
  }
  setAdminFormLoading(false)
};

const handleDeleteSuperAdmin = async (superAdminId) => {
  setAdminFormLoading(true)
  try {
      const response = await fetch(`rbac/delete_super_admin`, {
          method: 'POST', 
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ user_id: superAdminId }),
      });

      if (response.ok) {
          toast.success('User deleted successfully!');
          fetchData(); 
      } else {
          toast.error('User not deleted. Please try again.');
      }
  } catch (error) {
      toast.error('User not deleted. Please try again.');
  }
  setAdminFormLoading(false)
};

const handleAddOrEditRole = async () => {
  // console.log("Selected Operations:", selectedOperations);
  if (!roleTitle || !roleDescription || selectedOperations.length === 0) {
    toast.error("Please fill all fields and select at least one operation.");
    return;
  }

  // const formattedOperations = selectedOperations.join(',');
  const formattedOperations = selectedOperations
  .map(operation => operation === "checklist" ? "action" : operation)
  .join(',');
  const payload = {
    role: roleTitle,                    
    description: roleDescription,        
    operations: formattedOperations  
  };

  setIsAddRoleOpen(false);
  setAdminFormLoading(true);

  try {
    const url = isEditMode
      ? `/rbac/edit_role`   
      : `/rbac/add_role`;  

    const method = 'POST';
    const editPayload = isEditMode
      ? { ...payload, role_id: selectedRole.role_id } 
      : payload;

    const response = await fetch(url, {
      method,
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(editPayload),
    });

    if (response.ok) {
      setRoleTitle('');
      setRoleDescription('');
      setSelectedOperations([]);
      setIsEditMode(false);
      fetchData();
      toast.success(isEditMode ? 'Role edited successfully!' : 'Role added successfully!');
    } else {
      throw new Error();
    }
  } catch (error) {
    toast.error(isEditMode ? 'Role not edited. Please try again.' : 'Role not added. Please try again.');
  } finally {
    setAdminFormLoading(false);  
  }
};

  const handleAddOrEditRegulator = async () => {
    if (!regulatorTitle || !regulatorDescription || selectedLOBs.length === 0) {
      toast.error("Please fill all fields.");
      return;
    }
  
    const lobString = Array.isArray(selectedLOBs) ? selectedLOBs.join(',') : '';  
    // console.log('LOB String in Payload:', lobString);
  
    const payload = {
      regulator: regulatorTitle,                    
      LOB: lobString,   
      description: regulatorDescription,
    };
  
    setIsAddRegulatorOpen(false);
    setAdminFormLoading(true);
    // console.log(isEditMode);
    try {
      const url = isEditMode ? '/rbac/edit_regulator' : '/rbac/add_regulator';
      const method = isEditMode ? 'POST' : 'POST'; 
      const editPayload = isEditMode
        ? { ...payload, regulator_id: selectedRegulator.regulator_id } 
        : payload;
  
      // console.log('Payload:', editPayload); 
      // console.log('URL:', url); 
  
      const response = await fetch(url, {
        method,
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(editPayload),
      });
  
      const responseData = await response.json();
      // console.log('Response Data:', responseData); 
  
      if (response.ok) {
        setRegulatorTitle('');
        setRegulatorDescription('');
        setLob('');
        setIsAddRegulatorOpen(false);
        setIsEditMode(false);
        fetchData(); 
        toast.success(isEditMode ? 'Regulator edited successfully!' : 'Regulator added successfully!');
      } else {
        setRegulatorTitle('');
        setRegulatorDescription('');
        setLob('');
        setIsAddRegulatorOpen(false);
        setIsEditMode(false);
        fetchData();
        toast.error(isEditMode ? 'Regulator not edited. Please try again.' : 'Regulator not added. Please try again.');
        console.error('Error Response:', responseData); 
      }
    } catch (error) {
      setRegulatorTitle('');
      setRegulatorDescription('');
      setLob('');
      setIsAddRegulatorOpen(false);
      setIsEditMode(false);
      fetchData();
      console.error('Error:', error); 
      toast.error(isEditMode ? 'Regulator not edited. Please try again.' : 'Regulator not added. Please try again.');
    } finally {
      setAdminFormLoading(false); 
    }
  };
  
  const handleSelectionChange = (newSelections, type) => {
    // console.log("New Selections:", newSelections); 
    switch (type) {
      case 'operations':
        setSelectedOperations(newSelections);
        break;
      case 'LOBs':
        setSelectedLOBs(newSelections);
        break;
      case 'regulators':
        if (!newSelections.includes('RBI')) {
          newSelections = [...newSelections, 'RBI'];
        }
        setSelectedRegulators(newSelections);
        break;
      default:
        break;
    }
  };
  
  const handleAddOrEditACL = async () => {
    if (!username || !role || selectedRegulators.length === 0) {
      toast.error("Please fill all fields.");
      return;
    }
    const formattedRegulators = selectedRegulators.join(',');
    const payload = {
      username: username,
      role: role,
      regulators: formattedRegulators,  
    };
    setIsAddACLOpen(false);
    setAdminFormLoading(true);
  
    try {
      const url = isEditMode
        ? '/rbac/edit_acl_rule'
        : '/rbac/add_acl_rule';
  
      const method = isEditMode ? 'POST' : 'POST';
      const editPayload = isEditMode
        ? { ...payload, acl_id: selectedACL.acl_id }
        : payload;
  
      const response = await fetch(url, {
        method,
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(editPayload),
      });
      const result = await response.json();
      console.log(result)
  
      if (response.ok) {
        if (result.message && result.message.includes("User already has full access.")) {
          toast.error("The user already has full access.");
        } else
        setUsername('');
        setRole('');
        setSelectedRegulators([]); 
        setIsAddACLOpen(false);
        setIsEditMode(false);
        fetchData(); 
        toast.success(isEditMode ? 'ACL edited successfully!' : 'ACL added successfully!');
      } else {
        toast.error(isEditMode ? 'ACL not edited. Please try again.' : 'ACL not added. Please try again.');
      }
    } catch (error) {
      toast.error(isEditMode ? 'ACL not edited. Please try again.' : 'ACL not added. Please try again.');
    } finally {
      setAdminFormLoading(false);
    }
  };
  const handleAddOrEditSuperAdmin = async () => {
    if (!superAdminEmail) {
      toast.error("Please fill in the email.");
      return;
    }
  
    setIsAddSuperAdminOpen(false);
    setAdminFormLoading(true);
  
    try {
      const url = isEditMode
        ? '/rbac/edit_super_admin'
        : '/rbac/add_super_admin';
      
      const method = 'POST';
  
      // Prepare the payload based on edit or add mode
      let payload;
      if (isEditMode) {
        const response = await fetch('/users_details');
        const users = await response.json();
        const selectedUser = users.find((user) => user.user_name === superAdminEmail);
  
        if (!selectedUser) {
          toast.error('User not found.');
          setAdminFormLoading(false);
          return;
        }
  
        payload = {
          user_id: selectedUser.user_id,
          email_id: superAdminEmail,
        };
      } else {
        payload = {
          email_id: superAdminEmail,
        };
      }
  
      const response = await fetch(url, {
        method,
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(payload),
      });
  
      const result = await response.json(); // Parse the response to check for messages
  
      if (response.ok) {
        // Check if the message indicates the user is already a super admin
        if (result.message && result.message.includes("is already a Super Admin")) {
          toast.error(`User ${superAdminEmail} is already a super admin.`);
        } else {
          setSuperAdminEmail('');
          setIsAddSuperAdminOpen(false);
          setIsEditMode(false);
          fetchData(); // Refresh data
          toast.success(isEditMode ? 'Super Admin edited successfully!' : 'Super Admin added successfully!');
        }
      } else {
        toast.error(isEditMode ? 'Super Admin not edited. Please try again.' : 'Super Admin not added. Please try again.');
      }
    } catch (error) {
      toast.error(isEditMode ? 'Super Admin not edited. Please try again.' : 'Super Admin not added. Please try again.');
    } finally {
      setAdminFormLoading(false);
    }
  };
  
  const handleOpenAddACL = () => {
    setUsername('');
    setRole('');
    setRegulator('');
    setIsEditMode(false);
    setIsAddACLOpen(true);
  };
  const handleGenerateToken = () => {
    setAdminFormLoading(true);
    fetch('/generate_token', {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({})
    })
      .then(response => response.json())
      .then(data => {
        const token = data.token;
        setToken(token); 
        setError(null); 
      })
      .catch(error => {
        console.error('Failed to generate token:', error);
        setError('Failed to generate token. Please try again.'); 
      })
      .finally(() => {
        setAdminFormLoading(false); 
      });
  };
  const handleEditAcl = (acl) => {
    setSelectedACL(acl);
  setUsername(acl.username); 
  setRole(acl.role[0] || ""); 
  setSelectedRegulators(acl.regulator || []); 
  setIsEditMode(true);
  setIsAddACLOpen(true);
};
const toggleUserProfileDropdown = () => {
  setIsUserProfileDropdownOpen(!isUserProfileDropdownOpen);
};
const handleUserOptionClick = async (option) => {
  switch (option) {
    case 'Logout':
      try {
        const authTypeResponse = await fetch('/logout_type', {
          method: 'POST',
          credentials: 'include',
        });
        if (!authTypeResponse.ok) {
          throw new Error('Failed to get authentication type');
        }

        const authTypeData = await authTypeResponse.json();
        const authType = authTypeData.authentication_type;
        let logoutUrl;
        if (authType === 'Microsoft') {
          logoutUrl = '/microsoft/logout';
        } else if (authType === 'Google') {
          logoutUrl = '/google/logout';
        } else {
          throw new Error('Unknown authentication type');
        }

        const response = await fetch(logoutUrl, {
          method: 'GET',
          credentials: 'include',
        });
        if (response.ok) {
          window.location.href = '/';
        } else {
          console.error('Logout failed:', response);
        }
      } catch (error) {
        console.error('There was an error logging out', error);
      }
      break;
   
    case 'Prompt':
      window.location.href = '/chat';
      break;
    
    case 'Admin':
      window.location.href = '/admin';
      break;

    case 'Browse':
      setIsDarkMode(false);
      break;

    // case 'Multiple Regulator':
    //   setIsUserProfileDropdownOpen(false);
    //   setShowRegulatorForm(true);
    //   break;

    default:
      break;
  }
};
const handleCopyToken = () => {
  navigator.clipboard.writeText(token)
    .then(() => {
      alert('Token copied to clipboard!');
    })
    .catch((err) => {
      console.error('Could not copy token: ', err);
    });
};

const handleRegenerateToken = () => {
  fetch('/generate_token', {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({})
  })
    .then(response => response.json())
    .then(data => {
      const newToken = data.token;
      setToken(newToken); 
    })
    .catch(error => {
      console.error('Failed to regenerate token:', error);
    });
};

const [sortConfig, setSortConfig] = useState({ key: '', direction: '' });

const handleSort = (key) => {
  let direction = 'ascending';
  if (sortConfig.key === key && sortConfig.direction === 'ascending') {
    direction = 'descending';
  }
  setSortConfig({ key, direction });
};

const sortedData = (data, key) => {
  if (!key) return data;
  
  return [...data].sort((a, b) => {
    if (a[key] < b[key]) {
      return sortConfig.direction === 'ascending' ? -1 : 1;
    }
    if (a[key] > b[key]) {
      return sortConfig.direction === 'ascending' ? 1 : -1;
    }
    return 0;
  });
};

const renderTableContent = () => {
  const sortedRoles = sortedData(roles, 'role');
  const sortedRegulators = sortedData(regulators, 'regulator');
  const sortedACL = sortedData(acls, 'username');
  const sortedUsers = sortedData(users, 'user_name');
  const sortedSuperAdmin = sortedData(superAdmin, 'username');

  if (currentView === 'Roles') {
    return sortedRoles.filter((role) =>
      role.role.toLowerCase().includes(searchTerm) || role.description.toLowerCase().includes(searchTerm)
    ).map((role, index) => (
      <tr key={index}>
        <td>{role.role}</td>
        <td>
        <div className="chips-container">
          {role.operations?.map((operation, opIndex) => {
            const isEven = opIndex % 2 === 0;
            const chipClass = isEven ? 'read-chip' : 'write-chip';
            const displayOperation = operation === 'action' ? 'checklist' : operation;
            return (
              <span key={opIndex} className={`chip ${chipClass}`}>
                {displayOperation === '*' ? 'Full Access' : displayOperation}
              </span>
            );
          })}
        </div>
        </td>
        <td>{role.description}</td>
        <td>
          <div className="action-buttons">
            <button
              className="edit-button"
              onClick={() => {
                setSelectedRole(role);
                setRoleTitle(role.role);
                setRoleDescription(role.description);
                const updatedOperations = role.operations.map((operation) =>
                  operation === 'action' ? 'checklist' : operation
                );
                setSelectedOperations(updatedOperations);
                setIsAddRoleOpen(true);
                setIsEditMode(true);
              }}
            >
              <img src="/edit.png" alt="Edit" className="action-icon" />
            </button>
            <button
              className="delete-button"
              onClick={() => handleDeleteRole(role.role_id)}
            >
              <img src="/delete.png" alt="delete" className="action-icon" />
            </button>
          </div>
        </td>
      </tr>
    ));
  }

  if (currentView === 'Regulators') {
    return sortedRegulators.filter((regulator) =>
      regulator.regulator.toLowerCase().includes(searchTerm) || regulator.description.toLowerCase().includes(searchTerm)
    ).map((regulator, index) => (
      <tr key={index}>
        <td>{regulator.regulator}</td>
        <td>
          {/* <div className="chips-container">
            {regulator.lob?.split(',').map((lob, i) => (
              <span key={i} className="chip lob-chip">{lob.trim()}</span> 
            ))}
          </div> */}
          <div className="chips-container">
              {regulator.lob?.split(',').map((lob, i) => {
                const isEven = i % 2 === 0;
                const chipClass = isEven ? 'read-chip' : 'write-chip'; // Alternating chip class
                return (
                  <span key={i} className={`chip ${chipClass}`}>
                    {lob.trim()}
                  </span>
                );
              })}
            </div>

        </td>
        <td>{regulator.description}</td>
        <td>
          <div className="action-buttons">
            <button
              className="edit-button"
              onClick={() => {
                setSelectedRegulator(regulator);
                setRegulatorTitle(regulator.regulator);
                const lobArray = Array.isArray(regulator.lob) ? regulator.lob : regulator.lob.split(',');
                setSelectedLOBs(lobArray);
                setRegulatorDescription(regulator.description);
                setIsAddRegulatorOpen(true);
                setIsEditMode(true);
                // console.log(selectedLOBs)
                // console.log(setSelectedLOBs)
              }}
            >
              <img src="/edit.png" alt="Edit" className="action-icon" />
            </button>
            <button
              className="delete-button"
              onClick={() => handleDeleteRegulator(regulator.regulator_id)}
            >
              <img src="/delete.png" alt="delete" className="action-icon" />
            </button>
          </div>
        </td>
      </tr>
    ));
  }

  if (currentView === 'ACL') {
    return sortedACL.filter((acl) =>
      (acl.username?.toLowerCase() || '').includes(searchTerm) 
    ).map((acl, index) => (
      <tr key={index}>
        <td>{acl.username}</td>
        <td>
          {/* <div className="chips-container">
            {acl.regulator.map((regulator, i) => (
              <span key={i} className="chip lob-chip">
                {regulator}
              </span>
            ))}
          </div> */}
          <div className="chips-container">
            {acl.regulator.map((regulator, i) => {
              const isEven = i % 2 === 0;
              const chipClass = isEven ? 'read-chip' : 'write-chip'; // Alternating chip class
              return (
                <span key={i} className={`chip ${chipClass}`}>
                  {regulator}
                </span>
              );
            })}
          </div>

        </td>
        <td>{acl.role.join(', ')}</td> 
        <td>
          <div className="action-buttons">
            <button className="edit-button" onClick={() => handleEditAcl(acl)}>
              <img src="/edit.png" alt="Edit" className="action-icon" />
            </button>
            <button className="delete-button" onClick={() => handleDeleteAcl(acl.acl_id)}>
              <img src="/delete.png" alt="delete" className="action-icon" />
            </button>
          </div>
        </td>
      </tr>
    ));
  }

  if (currentView === 'User Management') {
    return sortedUsers.filter((user) =>
      (user.user_name?.toLowerCase() || '').includes(searchTerm) || (user.firstname?.toLowerCase() || '').includes(searchTerm)
    ).map((user, index) => {
      const fullName = `${user.firstname || ''} ${user.lastname || ''}`.trim() || 'Unknown Name';
      return (
        <tr key={index}>
          <td>{fullName}</td>
          <td>{user.user_name}</td>
          <td>{user.invitation_status}</td>
          <td>{user.creation_ts}</td>
          <td>
            <div className="action-buttons">
              <button className="edit-button" onClick={() => handleEditUser(user)} disabled={true}>
                <img src="/edit.png" alt="Edit" className="action-icon" />
              </button>
              <button className="delete-button" onClick={() => handleDeleteUser(user.user_id)}>
                <img src="/delete.png" alt="delete" className="action-icon" />
              </button>
            </div>
          </td>
        </tr>
      );
    });
  }

  if (currentView === 'Super Admins') {
    return sortedSuperAdmin.filter((superadmin) =>
      (superadmin.username?.toLowerCase() || '').includes(searchTerm)
    ).map((superadmin, index) =>(
        <tr key={index}>
          <td>{superadmin.username}</td>
          <td>
            <div className="action-buttons">
              <button className="edit-button" onClick={() => handleEditSuperAdmin(superadmin)}>
                <img src="/edit.png" alt="Edit" className="action-icon" />
              </button>
              <button className="delete-button" onClick={() => handleDeleteSuperAdmin(superadmin.acl_id)}>
                <img src="/delete.png" alt="delete" className="action-icon" />
              </button>
            </div>
          </td>
        </tr>
    ));
  }

  return null;
};


  
  return (
    <>
    <div className="rbac-container">
      <div className='complieaze-header'>
        <div className='admin-complieaze-logo'>
          <a href="https://complieaze.ai" target="_blank" rel="noopener noreferrer">
            <div className="logo">
              <span style={{ color: "#3F9BA1" }}>Compli</span>
              <span style={{ color: "#000" }}>eaze</span>
            </div>
          </a>
          <h2 className="titlee">Regulatory compliance assistant</h2>
        </div>
        <div className="user-profile-container">
                    <button id="profile-btn" className="user-profile-button" type="button" onClick={toggleUserProfileDropdown}>
                      <div className="user-profile-image">
                        <img src={process.env.PUBLIC_URL + '/account_circle.png'} alt="User Profile" />
                      </div>
                    </button>
                    {isUserProfileDropdownOpen && (
                      <div className="user-profile-dropdown">
                        <div onClick={() => handleUserOptionClick('Logout')}>Logout</div>
                        <div onClick={() => handleUserOptionClick('Prompt')}>Prompt</div>
                      </div>
                    )}
                  </div>
      </div>

      <div className="admin-body-container">
        <aside className="sidebar">
        <nav className="sidebar-menu">
            <button
                className={`sidebar-button ${currentView === 'Roles' ? 'active' : ''}`}
                onClick={() => setCurrentView('Roles')}
            >
                Roles
            </button>
            <button
                className={`sidebar-button ${currentView === 'Regulators' ? 'active' : ''}`}
                onClick={() => setCurrentView('Regulators')}
            >
                Regulators
            </button>
            <button
                className={`sidebar-button ${currentView === 'ACL' ? 'active' : ''}`}
                onClick={() => setCurrentView('ACL')}
            >
                Access Control(ACL)
            </button>
            <button
                className={`sidebar-button ${currentView === 'User Management' ? 'active' : ''}`}
                onClick={() => setCurrentView('User Management')}
            >
                User Management
            </button>
            <button
                className={`sidebar-button ${currentView === 'Super Admins' ? 'active' : ''}`}
                onClick={() => setCurrentView('Super Admins')}
            >
                Super Admins
            </button>
            {/* <button
                className={`sidebar-button ${currentView === 'Generate Token' ? 'active' : ''}`}
                onClick={() => setCurrentView('Generate Token')}
            >
                Generate Token
            </button> */}
            </nav>

        </aside>

        <div className="main-container">
        <div className='admin-container'>
            <div className='table-header'>
            <h3>{currentView === 'ACL' ? 'Access Control (ACL)' : currentView}</h3>
            <div className='search-container'>
    <input
      type='text'
      className='search-input'
      value={searchTerm}
      placeholder={
        currentView === 'User Management' || currentView === 'ACL'
          ? 'Search Username...'
          : `Search ${currentView}...`
      }
      onChange={(e) => handleSearch(e.target.value)}
    />

              {currentView === 'Roles' && <button className='add-role-button'onClick={handleAddRoleClick}>Add Role</button>}
              {currentView === 'Regulators' && <button className='add-role-button' onClick={handleAddRegulatorClick}>Add Regulator</button>}
              {currentView === 'ACL' && <button className='add-role-button' onClick={handleAddACLClick}>Add Access(ACL)</button>}
              {currentView === 'User Management' && <button className='add-role-button' onClick={handleAddUserClick}>Add User</button>}
              {currentView === 'Super Admins' && <button className='add-role-button' onClick={handleSuperAdminClick}>Add Super Admin</button>}
              {currentView === 'Generate Token' && <button className='add-role-button' onClick={handleGenerateToken}>Generate Token</button>}
            </div>
            </div>
            <hr/>
        <div className='admin-table-container'>
          {error ? (
            <div className="error-message">Data not Found</div>
          ) : AdminFormLoading ? (
            <div className="admin-loader-container">
              <div className="formloader"></div>
            </div>
          ) : (
            <>
                    {currentView === 'Generate Token' ? (
                        <div className='token-content'>
                            <h3>Generate Token</h3>
                            <p>{token}</p>
                            <div className="token-buttons">
                                <button className="copy-token-button" onClick={handleCopyToken}>
                                    Copy Token
                                </button>
                                <button className="regenerate-token-button" onClick={handleRegenerateToken}>
                                    Regenerate Token
                                </button>
                            </div>
                        </div>
                    ) : (
                      <table className="custom-table">
                      <thead className="custom-header">
                        <tr>
                          <th onClick={() => handleSort('username')}>
                            {currentView === 'ACL' || currentView === 'User Management' ? 'Username' : currentView}
                            <span className={`sort-icons ${sortConfig.key === 'username' && sortConfig.direction}`}>
                              <i className="fas fa-arrow-up"></i>
                              <i className="fas fa-arrow-down"></i>
                            </span>
                          </th>
                          {currentView === 'Roles' && (
                            <th onClick={() => handleSort('operations')}>
                              Operations
                              <span className={`sort-icons ${sortConfig.key === 'operations' && sortConfig.direction}`}>
                                <i className="fas fa-arrow-up"></i>
                                <i className="fas fa-arrow-down"></i>
                              </span>
                            </th>
                          )}
                          {currentView === 'Regulators' && (
                            <th onClick={() => handleSort('lob')}>
                              LOB
                              <span className={`sort-icons ${sortConfig.key === 'lob' && sortConfig.direction}`}>
                                <i className="fas fa-arrow-up"></i>
                                <i className="fas fa-arrow-down"></i>
                              </span>
                            </th>
                          )}
                          {currentView === 'User Management' && (
                            <>
                              <th onClick={() => handleSort('email')}>
                                Email
                                <span className={`sort-icons ${sortConfig.key === 'email' && sortConfig.direction}`}>
                                  <i className="fas fa-arrow-up"></i>
                                  <i className="fas fa-arrow-down"></i>
                                </span>
                              </th>
                              <th onClick={() => handleSort('invitation_status')}>
                                Invitation Status
                                <span className={`sort-icons ${sortConfig.key === 'invitation_status' && sortConfig.direction}`}>
                                  <i className="fas fa-arrow-up"></i>
                                  <i className="fas fa-arrow-down"></i>
                                </span>
                              </th>
                              <th onClick={() => handleSort('creation_ts')}>
                                User Created On
                                <span className={`sort-icons ${sortConfig.key === 'creation_ts' && sortConfig.direction}`}>
                                  <i className="fas fa-arrow-up"></i>
                                  <i className="fas fa-arrow-down"></i>
                                </span>
                              </th>
                            </>
                          )}
                          {(currentView === 'Roles' || currentView === 'Regulators') && (
                            <th onClick={() => handleSort('description')}>
                              Description
                              <span className={`sort-icons ${sortConfig.key === 'description' && sortConfig.direction}`}>
                                <i className="fas fa-arrow-up"></i>
                                <i className="fas fa-arrow-down"></i>
                              </span>
                            </th>
                          )}
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>{renderTableContent()}</tbody>
                    </table>
                    
             )}
                </>
          )}
        </div>
      </div>

        </div>
      </div>
      {isAddRoleOpen && (
  <div className="add-role-modal">
    <div className="add-role-form">
      <div className="add-role-header">
        <h3>{isEditMode ? 'Edit Role' : 'Add Role'}</h3>
        <button className="close-modal" onClick={handleCloseAddRole}>×</button>
      </div>
      <label>Role:</label>
          <textarea
            className="admin-role"
        placeholder="Role Title"
            value={roleTitle}
            onChange={(e) => setRoleTitle(e.target.value)}
          />
           <label>Role Description:</label>
          <textarea
            className="admin-description"
        placeholder="Description"
            value={roleDescription}
            onChange={(e) => setRoleDescription(e.target.value)}
          />

      <div>
       
         <div>
         <label>Operations:</label>
      <MultiSelectDropdown
        optionsList={operationsList} 
        selectedOptions={selectedOperations}
        onSelectionChange={(newSelections) => handleSelectionChange(newSelections, 'operations')}
        placeholder="Select Operation..."
      />
       {/* <div className="selected-operations">
          {selectedOperations.map((operation, index) => (
            <Chip
              key={index}
              label={operation}
              onDelete={() => handleRemoveOperation(operation)} 
              variant="outlined" 
              style={{ margin: '4px' }} 
            />
          ))}
        </div> */}
    </div>
      </div>

      <div className="modal-footer">
        <button className="cancel-button" onClick={handleCloseAddRole}>
          Cancel
        </button>
        <button
          className="admin-submit-button"
          onClick={handleAddOrEditRole}
          disabled={AdminFormLoading}
        >
          {isEditMode ? 'Edit Role' : 'Add Role'}
        </button>
      </div>
    </div>
  </div>
)}



{isAddRegulatorOpen && (
  <div className="add-regulator-modal">
    <div className="add-regulator-form">
      <div className="add-role-header">
        <h3>{isEditMode ? 'Edit Regulator' : 'Add Regulator'}</h3>
        <button className="close-modal" onClick={handleCloseAddRegulator}>×</button>
      </div>
      <label>Regular:</label>
      <textarea
        className='admin-role'
        placeholder="Regulator Title"
        value={regulatorTitle}
        disabled={isEditMode}
        onChange={(e) => setRegulatorTitle(e.target.value)}
      />
      <label>Regular Description:</label>
      <textarea
        className='admin-role'
        placeholder="Regulator Description"
        value={regulatorDescription}
        onChange={(e) => setRegulatorDescription(e.target.value)}
      />
    <label>LOB:</label>
      <MultiSelectDropdown
        optionsList={LOB} 
        selectedOptions={selectedLOBs}
        onSelectionChange={handleLOBSelect}
        placeholder="Select LOB..."
      />
       {/* <div className="selected-lobs">
          <label>Selected LOB:</label>
          {selectedLOBs.map((lob, index) => (
            <Chip
              key={index}
              label={lob}
              onRemove={() => handleRemoveLOB(lob)}
                />
            ))}
          </div> */}
      <div className="modal-footer">
        <button className="cancel-button" onClick={handleCloseAddRegulator}>Cancel</button>
        <button className="admin-submit-button" onClick={handleAddOrEditRegulator}>
          {isEditMode ? 'Save Regulator' : 'Add Regulator'}
        </button>
      </div>
    </div>
  </div>
)}


{isAddACLOpen && (
        <div className="add-regulator-modal">
            <div className="add-regulator-form">
      <div className="add-role-header">
        <h3>{isEditMode ? 'Edit ACL' : 'Add ACL'}</h3>
        <button className="close-modal" onClick={handleCloseAddACL}>×</button>
      </div>
      <label>Username:</label>
      <select
        className="acl-dropdown"
        value={username}
        onChange={(e) => setUsername(e.target.value)}
      >
        <option value="">Select User</option>
        {userList.map(user => (
          <option key={user.id} value={user.id}>{user.name}</option>
        ))}
      </select>
      <label>Role:</label>
      <select
        className="acl-dropdown"
        value={role}
        onChange={(e) => setRole(e.target.value)}
      >
        <option value="">Select Role</option>
        {rolesList.filter(item => item.role !== 'Super Admin' && item.role !== 'Admin').map(roleItem => (
          <option key={roleItem.role_id} value={roleItem.role}>{roleItem.role}</option>
        ))}
      </select>
      <div>
      <label>Regular:</label>
      <MultiSelectDropdown
        optionsList={regulatorsList} 
        selectedOptions={selectedRegulators}
        onSelectionChange={(newSelections) => handleSelectionChange(newSelections, 'regulators')}
        placeholder="Select regulator..."
      />
        {/* <div className="selected-regulators">
          <label>Selected Regulators:</label>
          {selectedRegulators.map((regulator, index) => (
            <Chip
              key={index}
              label={regulator}
              onRemove={() => handleRemoveRegulator(regulator)}
            />
          ))}
        </div> */}
      </div>

      <div className="modal-footer">
        <button className="cancel-button" onClick={handleCloseAddACL}>Cancel</button>
        <button className="admin-submit-button" onClick={handleAddOrEditACL}>
          {isEditMode ? 'Edit ACL' : 'Add ACL'}
        </button>
      </div>
    </div>
  </div>
)}
    {isAddUserOpen && (
  <div className="add-regulator-modal">
    <div className="add-regulator-form">
      <div className="add-role-header">
        <h3>{isEditMode ? 'Edit User' : 'Invite User'}</h3>
        <button className="close-modal" onClick={handleCloseAddUser}>×</button>
      </div>
      <label>Email:</label>
      <textarea 
        placeholder="Email" 
        value={email} 
        onChange={(e) => setEmail(e.target.value)} 
      />
       <label>Role:</label>
  <select
        className="acl-dropdown"
        value={role}
        onChange={(e) => setRole(e.target.value)}
      >
        <option value="">Select Role</option>
        {rolesList.filter(item => item.role !== 'Super Admin' && item.role !== 'Admin').map(roleItem => (
          <option key={roleItem.role_id} value={roleItem.role}>{roleItem.role}</option>
        ))}
      </select>

      <div>
      <label>Regular:</label>
  <MultiSelectDropdown
        optionsList={regulatorsList} 
          selectedOptions={selectedRegulators}
        onSelectionChange={(newSelections) => handleSelectionChange(newSelections, 'regulators')}
        placeholder="Select role..."
      />
        {/* <div className="selected-regulators">
          <label>Selected Regulators:</label>
          {selectedRegulators.map((regulator, index) => (
            <Chip
              key={index}
              label={regulator}
              onRemove={() => handleRemoveRegulator(regulator)}
            />
          ))}
        </div> */}
      </div>

      <div className="modal-footer">
        <button className="cancel-button" onClick={handleCloseAddUser}>Cancel</button>
        <button className="admin-submit-button" onClick={handleAddOrEditUser}>
          {isEditMode ? 'Edit User' : 'Invite User'}
        </button>
      </div>
    </div>
  </div>
)}
{isAddSuperAdminOpen && (
  <div className="add-regulator-modal">
    <div className="add-regulator-form">
      <div className="add-role-header">
        <h3>{isEditMode ? 'Edit Super Admin' : 'Add Super Admin'}</h3>
        <button className="close-modal" onClick={handleCloseAddSuperAdmin}>×</button>
      </div>

      {/* <textarea 
        placeholder="Email" 
        value={superAdminEmail} 
        onChange={(e) => setSuperAdminEmail(e.target.value)} 
      /> */}
       <label>Username:</label>
  <select
        className="acl-dropdown"
        value={superAdminEmail}
        onChange={(e) => setSuperAdminEmail(e.target.value)}
      >
        <option value="">Select User</option>
        {userList.map(user => (
          <option key={user.id} value={user.id}>{user.name}</option>
        ))}
      </select>

      <div className="modal-footer">
        <button className="cancel-button" onClick={handleCloseAddSuperAdmin}>Cancel</button>
        <button className="admin-submit-button" onClick={handleAddOrEditSuperAdmin}>
          {isEditMode ? 'Edit Super Admin' : 'Add Super Admin'}
        </button>
      </div>
    </div>
  </div>
)}




    </div>
    <ToastContainer position="top-center" autoClose={5000} hideProgressBar={false} newestOnTop={true} closeOnClick pauseOnHover draggable />
    </>
  );
};

export default RBACPage;
