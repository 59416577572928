import React, { useState, useRef, useEffect, Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import MobileMenu from './MobileMenu';
import ReactMarkdown from 'react-markdown';
import './Chat.css';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { DataSet } from 'vis-data/peer/umd/vis-data.min.js';
import { Network } from 'vis-network';
import { readString } from 'react-papaparse';
import { faVolumeUp } from '@fortawesome/free-solid-svg-icons';
import { faMicrophone } from '@fortawesome/free-solid-svg-icons';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { useParams } from 'react-router-dom';
import { format } from 'date-fns';
import { DataGrid, GridToolbarExport } from "@mui/x-data-grid";
import Modal from './Modal';
import axios from 'axios';
import { Document, Page } from 'react-pdf';
import Plot from "react-plotly.js";
import PdfModalPage from './PdfModalPage';
import { Select, MenuItem, FormControl, InputLabel, OutlinedInput, Checkbox, ListItemText, Chip, Button, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { faArrowDown } from '@fortawesome/free-solid-svg-icons';
import { Viewer } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import { FaQuestionCircle, FaChartBar, FaExchangeAlt, FaListAlt, FaClipboardCheck, FaFileAlt, FaInfoCircle } from 'react-icons/fa';
import DOMPurify from 'dompurify';
import { faPaperclip } from '@fortawesome/free-solid-svg-icons';
import PopupForm from './PopupForm';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ChatUI = () => {

  const urlParams = new URLSearchParams(window.location.href);
  const obj = Object.fromEntries(urlParams);
  // var cir_number = obj['https://complieaze.ai/chat?circular_number']
  var cir_date = obj['date']

  // if (typeof cir_date === 'undefined') {
  //   var circularNumber = `/circular ${cir_number}`
  // } else {
  //   var circularNumber = `/circular ${cir_number} ${cir_date}`
  // }
  // if (typeof cir_number === 'undefined') {
  //   var circularNumber = '';
  // }

  const [browseInputText, setBrowseInputText] = useState("");
  const [messages, setMessages] = useState([]);
  const [chatHistory, setChatHistory] = useState([]);
  const [inputText, setInputText] = useState([]);
  const [userMessage, setUserMessage] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [isBrowseLoading, setBrowseLoading] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isFormLoading, setFormLoading] = useState(false);
  const [activeChatIndex, setActiveChatIndex] = useState(null);
  const chatContainerRef = useRef(null);
  const [isLogoutVisible, setIsLogoutVisible] = useState(false);
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const [responseData, setResponseData] = useState(null);
  const [suggestionsVisible, setSuggestionsVisible] = useState(false);
  const [selectedTopic, setSelectedTopic] = useState('');
  const [selectedOption, setSelectedOption] = useState('Context');
  const [apiResponse, setApiResponse] = useState([]);
  const [viewMode, setViewMode] = useState("list");
  const [dimension, setDimension] = useState("2D");
  // const [showRegulatorForm, setShowRegulatorForm] = useState(false);
  const [pdfmodalOpen, setpdfModalOpen] = useState(false);
  const [selectedPdfUrl, setSelectedPdfUrl] = useState('');
  const [showMore, setShowMore] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalDataCount, setTotalDataCount] = useState(0);
  const [noDataMessage, setNoDataMessage] = useState('');
  const [regulators, setRegulators] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalData, setModalData] = useState(null);
  const [isRecording, setIsRecording] = useState(false);
  const [showRegulatorForm, setShowRegulatorForm] = useState(false);
  const [topicsByRegulator, setTopicsByRegulator] = useState({});
  const [errorMessage, setErrorMessage] = useState(null);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [isUserProfileDropdownOpen, setIsUserProfileDropdownOpen] = useState(false);
  const [isCommentFormOpen, setIsCommentFormOpen] = useState(false);
  const [isListening, setIsListening] = useState(false);
  const [transcript, setTranscript] = useState('');
  const colors = ['orange', 'white', 'green'];
  const dotStyle = { marginRight: '5px' };
  const [dotSequence, setDotSequence] = useState(0);
  const [fetchAudioTrigger, setFetchAudioTrigger] = useState(false);
  const [selectedRegulator, setSelectedRegulator] = useState([]);
  const [availableTopics, setAvailableTopics] = useState([]);
  const [selectedTopics, setSelectedTopics] = useState([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [selectedMode, setSelectedMode] = useState('prompt'); 
  
  const openPdfInModal = (pdfUrl) => {
    setSelectedPdfUrl(pdfUrl);
    setpdfModalOpen(true);
  };

  const closePdfModal = () => {
    setSelectedPdfUrl('');
    setpdfModalOpen(false);
  };

  const handleFirstDropdownChange = event => {
    setSelectedTopic(event.target.value);
  };

  const handleSecondDropdownChange = event => {
    setSelectedOption(event.target.value);
  };


  const handleCircularNumberClick = (circularString) => {
    const circularNumberMatch = circularString.match(/RBI\/\d{4}-\d{4}\/\d+\n(.*)/);
    if (circularNumberMatch) {
      const extractedCircularNumber = circularNumberMatch[1];
      handleSendMessage(`/circular ${extractedCircularNumber}`);
      setIsDarkMode(false); 
    }
else {
  handleSendMessage(`/circular ${circularString}`);
      setIsDarkMode(false);
    };
    scrollToBottom();
  }
  
  const handleBrowseInputChange = event => {
    setBrowseInputText(event.target.value);
  };
  const handleClearSearch = () => {
    setSelectedTopic('');
    setStartDate(null);
    setEndDate(null);
    setSelectedOption('Context');
    setBrowseInputText('');
  };
  useEffect(() => {
    setTotalDataCount(apiResponse.length);
  }, [apiResponse]);

  const handleBrowseSubmit = async () => {
    const payload = {};
  
    if (selectedTopic) payload.topic = selectedTopic;
    if (startDate) payload.startDate = format(startDate, 'dd-MM-yyyy');
    if (endDate) payload.endDate = format(endDate, 'dd-MM-yyyy');
    if (selectedOption && browseInputText) {
      let inputText = browseInputText;
      if (selectedOption.toLowerCase().replace(/ /g, '_') === 'circular_number') {
        inputText = inputText.replace(/\\n/g, '\n');
      }
  
      payload[selectedOption.toLowerCase().replace(/ /g, '_')] = inputText;
    }
  
    setBrowseLoading(true);
    setApiResponse([]);
    setNoDataMessage('');
  
    try {
      const response = await axios.post('/browse_document', payload);
      const responseData = response.data;
  
      // Check if responseData is an array and contains a single string message
      if (Array.isArray(responseData) && responseData.length === 1 && typeof responseData[0] === 'string') {
        if (responseData[0].includes('No documents found matching the search criteria.')) {
          setNoDataMessage('No documents found matching the search criteria.');
          setApiResponse([]);
        } else {
          setApiResponse(responseData);
        }
      } else if (typeof responseData === 'string' && responseData.includes('No documents found matching the search criteria.')) {
        setNoDataMessage('No documents found matching the search criteria.');
        setApiResponse([]);
      } else if (Array.isArray(responseData) && responseData.length === 0) {
        setNoDataMessage('No documents found matching the search criteria.');
      } else if (responseData.status === 403) {
        // Show toast notification if access is forbidden
        toast.error('You don’t have access to this regulator.');
      }else {
        setApiResponse(responseData);
      }
    } catch (error) {
      console.error('Error submitting browse data:', error);
      setNoDataMessage('An error occurred while searching for documents.');
    } finally {
      setBrowseLoading(false);
    }
  };

  const generateSecureRandom = () => {
    const array = new Uint32Array(1);
    window.crypto.getRandomValues(array);
    return array[0] / (0xFFFFFFFF + 1); 
  };
  
  const generateDummyEmbeddings = (numPoints) => {
    const embeddings2D = Array.from({ length: numPoints }, () => [
      generateSecureRandom(),
      generateSecureRandom(),
    ]);
  
    const embeddings3D = Array.from({ length: numPoints }, () => [
      generateSecureRandom(),
      generateSecureRandom(),
      generateSecureRandom(),
    ]);
  
    return { embeddings2D, embeddings3D };
  };
  
  const graphData = generateDummyEmbeddings(apiResponse.length);

  const toggleDimension = () => {
    setDimension(dimension === "2D" ? "3D" : "2D");
  };
 
  const graphLayout2D = {
    title: "Document Similarity Graph",
    autosize: true,
    margin: {
      l: 60,
      r: 60,
      b: 50,
      t: 70,
      pad: 10,
    },
    xaxis: { title: "Dimension 1" },
    yaxis: { title: "Dimension 2" },
  };

  const graphLayout3D = {
    title: "Document Similarity Graph",
    autosize: true,
    margin: {
      l: 60,
      r: 60,
      b: 50,
      t: 70,
      pad: 10,
    },
    scene: {
      xaxis: { title: "Dimension 1" },
      yaxis: { title: "Dimension 2" },
      zaxis: { title: "Dimension 3" },
    },
  };

  const suggestionIndexRef = useRef(-1);
  const [selectedSuggestionIndex, setSelectedSuggestionIndex] = useState(-1);
  const inputRef = useRef(null);

  const handleButtonClick = (commandText) => {
    setInputText(commandText);
    setChatPlaceholder('');
  };
  const placeholderText = () => {
    return (
      <div className="containerr">
        <div className="row1">
          <button className="box">
            <img src="/question-icon.png" alt="Question Icon" className="icon" />
            <h4>Question</h4>
            <img src="/info.png" alt="Info Icon" className="info-icon" />
            <span className="header-tooltip">
              <ul>
                <li><strong>Description:</strong> Get an answer to your query resolved along with appropriate references</li>
                <li><strong>Syntax:</strong> /question &lt;user_query&gt;</li>
                <li><strong>Example:</strong> <span className="clickable-link"  onClick={() => handleSendMessage("/question How often should IRRBB guidelines be reviewed?")}>/question How often should IRRBB guidelines be reviewed?</span></li>
                <li><strong>Natural Language example:</strong> How often should IRRBB guidelines be reviewed?</li>
              </ul>
            </span>
          </button>
          <button className="box">
            <img src="/references-icon.png" alt="Question Icon" className="icon" />
            <h4>References</h4>
            <img src="/info.png" alt="Info Icon" className="info-icon" />
            <span className="header-tooltip">
            <ul>
            <li><strong>Description:</strong> Get an interactive graph showing the references made by a particular circular</li>
            <li><strong>Syntax:</strong> /references &lt;circular_number&gt;</li>
            <li><strong>Example:</strong> 
            <span
            className="clickable-link" onClick={() => handleSendMessage("/references DoR.RET.REC.59/12.01.001/2023-24")}>/references DoR.RET.REC.59/12.01.001/2023-24</span></li>
            <li><strong>Natural Language example:</strong> What are the circulars referred by circular DoR.RET.REC.59/12.01.001/2023-24</li>
          </ul>
            </span>
          </button>
          <button className="box">
            <img src="/changes-icon.png" alt="Question Icon" className="icon" />
            <h4>Changes</h4>
            <img src="/info.png" alt="Info Icon" className="info-icon" />
            <span className="header-tooltip">
            <ul>
            <li><strong>Description:</strong> Get an interactive graph showing the changes made by a particular circular</li>
            <li><strong>Syntax:</strong> /changes &lt;circular_number&gt;</li>
            <li><strong>Example:</strong> 
            <span
            className="clickable-link" onClick={() => handleSendMessage("/changes A.P. (DIR Series) Circular No. 13")}>/changes A.P. (DIR Series) Circular No. 13</span></li>
            <li><strong>Natural Language example:</strong> What are the changes made by circular A.P. (DIR Series) Circular No. 13</li>
          </ul>
            </span>
          </button>
          <button className="box">
            <img src="/action-icon.png" alt="Question Icon" className="icon" />
            <h4>Checklist</h4>
            <img src="/info.png" alt="Info Icon" className="info-icon" />
            <span className="header-tooltip">
            <ul>
            <li><strong>Description:</strong> Get a table showing the action items present in particular circular</li>
            <li><strong>Syntax:</strong> /checklist &lt;circular_number&gt;</li>
            <li><strong>Example:</strong> 
            <span
            className="clickable-link" onClick={() => handleSendMessage("/checklist DoR.RET.REC.59/12.01.001/2023-24")}>/checklist DoR.RET.REC.59/12.01.001/2023-24</span></li>
            <li><strong>Natural Language example:</strong> What are action items mentioned in the circular DoR.RET.REC.59/12.01.001/2023-24</li>
          </ul>
            </span>
          </button>
          <button className="box">
            <img src="/compliance-icon.png" alt="Question Icon" className="icon" />
            <h4>Browse</h4>
            <img src="/info.png" alt="Info Icon" className="info-icon" />
            <span className="header-tooltip">
            <ul>
            <li><strong>Description:</strong> Get an interactive table showing all the RBI circulars</li>
            <li><strong>Syntax:</strong> /browse</li>
            <li><strong>Example:</strong> 
            <span
            className="clickable-link" onClick={() => handleSendMessage("/browse")}>/browse</span></li>
            <li><strong>Natural Language example:</strong> 1. List all the RBI circulars from last month <br /> 2. List all the RBI circulars from first quarter of 2023</li>
          </ul>
            </span>
          </button>
          <button className="box">
            <img src="/circular-icon.png" alt="Question Icon" className="icon" />
            <h4>Circular</h4>
            <img src="/info.png" alt="Info Icon" className="info-icon" />
            <span className="header-tooltip">
            <ul>
            <li><strong>Description:</strong> Get the important information about a circular, an AI generated summary along with three buttons to access the references, changes and actions related to that circular.</li>
            <li><strong>Syntax:</strong> /circular &lt;circular_number&gt;</li>
            <li><strong>Example:</strong>
            <span
            className="clickable-link" onClick={() => handleSendMessage("/circular DoR.REG/LIC.No.72/16.05.000/2023-24")}>/circular DoR.REG/LIC.No.72/16.05.000/2023-24</span></li>
            <li><strong>Natural Language example:</strong> Natural language support for this command is still under development</li>
          </ul>
            </span>
          </button>
        </div>
      </div>
    );
  };
  const handleBackToMenu = () => {
    setMessages([]);
    setChatPlaceholder(placeholderText);
  };

  const [topicsData, setTopicsData] = useState({});

  const handleChatContainerError = () => {
    setErrorMessage("Complieaze is down. Please contact the team.");
    setIsErrorModalOpen(true);
  };

  useEffect(() => {
    if (selectedRegulator) {
      setAvailableTopics(topicsByRegulator[selectedRegulator]);
      setSelectedTopics([]);
    }
  }, [selectedRegulator]);

  useEffect(() => {
    async function fetchTopics() {
      try {
        const response = await axios.get('/get_topics');
        setTopicsData(response.data);
      } catch (error) {
        console.error('Error fetching topics data:', error);
      }
    }
    fetchTopics();
  }, []);


  // const regulators = ['RBI', 'SEBI', 'INTERNAL GUIDELINES'];

  // const handleRegulatorChange = (event) => {
  //   const { value } = event.target;
  //   setSelectedRegulator(typeof value === 'string' ? value.split(',') : value);
  // };
 const handleRegulatorChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedRegulator(value); // Update the selected regulator state
  };

  const fetchTopics = async () => {
    try {
      // Prepare the regulators array for the payload
      const payloadRegulators = selectedRegulator.map(regulator => regulator); 
      const response = await axios.post('/get_topic', { regulators: payloadRegulators });
      // console.log('Topics Response:', response.data);
    } catch (error) {
      console.error('Error fetching topics data:', error);
    }
  };


  const fetchRegulator = async () => {
    try {
      const response = await axios.get('/get_regulator');
      const data = response.data;
      setRegulator(data.regulator);
      setMetadata(data.metadata);
      setQAContext(`${data.regulator} all documents`)

    } catch (error) {
      console.error('Error fetching regulator:', error);
    }
  };

  const handleSubmit = async () => {
    setFormLoading(true);
    try {
      await fetchTopics();
      await fetchRegulator();
    } catch (error) {
      console.error('Error during form submission:', error);
    } finally {
      setFormLoading(false);
      handleBrowseSubmit()
      setShowRegulatorForm(false);
    }
  };

  const handleCloseForm = () => {
    setShowRegulatorForm(false);
  };
  const fetchRegulators = async () => {
    try {
      const response = await fetch('/rbac/select_regulator_access'); 
      const data = await response.json();
      const regulatorList = data.regulators.map((regulator) => ({
        name: regulator,
      }));
      
      setRegulators(regulatorList); 
    } catch (error) {
      console.error('Error fetching regulators:', error);
    }
  };
  
  const handleToggleForm = () => {
    setShowRegulatorForm((prev) => !prev); 
    if (!showRegulatorForm) {
      fetchRegulators(); 
    }
  };

  const handleTopicChange = (event) => {
    const { value } = event.target;
    const updatedTopics = typeof value === 'string' ? value.split(',') : value;
    setSelectedTopics(updatedTopics);
    setTopicsByRegulator(prevState => ({
      ...prevState,
      [selectedRegulator]: updatedTopics,
    }));
  };

  const renderSelectedValue = (selected) => {
    if (selected.length > 2) {
      return (
        <div className="selected-topics">
          {selected.slice(0, 2).map((value) => (
            <Chip key={value} label={value} style={{ margin: 2 }} />
          ))}
          <span>...</span>
        </div>
      );
    }
    return selected.map((value) => (
      <Chip key={value} label={value} style={{ margin: 2 }} />
    ));
  };


  const closeModal = () => {
    setIsErrorModalOpen(false);
  };

  const [chatPlaceholder, setChatPlaceholder] = useState(placeholderText);

  useEffect(() => {
    let interval;
    if (isRecording) {
      interval = setInterval(() => {
        setDotSequence((dotSequence) => (dotSequence + 1) % 3);
      }, 500);
    } else {
      setDotSequence(0);
    }
    return () => clearInterval(interval);
  }, [isRecording]);


  const toggleUserProfileDropdown = () => {
    setIsUserProfileDropdownOpen(!isUserProfileDropdownOpen);
  };

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
  };

  const allSuggestions = [
    '/question How often should IRRBB policies be reviewed?',
    '/question What is the limit for offline payment transactions as of 2024?',
    '/question What is KYC?',
    '/question What is AML?',
    '/question What are the safeguards that banks should take for preventing misuse of lost/stolen cards?',
    '/question List the steps that banks with foreign branches should do to translate their financial statements',
    '/question What should banks do to obtain consent for sharing customer information?',
    '/question What should banks do to ensure that the consumer knows about the calculation of finance charges?',
    '/question What should banks do before reporting the default status of a credit card holder to CIBIL?',
    '/question What should banks do so that the identity of the person withdrawing cash from the ATM can be established?',
    '/question How should the request for closure of credit card be treated by the bank ?',
    '/circular DoR.REG/LIC.No.72/16.05.000/2023-24',
    '/changes A.P. (DIR Series) Circular No. 13',
    '/checklist Master Direction on Information Technology Governance, Risk, Controls and Assurance Practices',
    '/checklist Master Circular – Housing Finance',
    '/references DoR.RET.REC.59/12.01.001/2023-24',
    '/browse',
    '/question',
    'How often should IRRBB policies be reviewed?',
    'What is the limit for offline payment transactions as of 2024?',
    'What is KYC?',
    'What is AML?',
    'What are the safeguards that banks should take for preventing misuse of lost/stolen cards?',
    'List the steps that banks with foreign branches should do to translate their financial statements',
    'What should banks do to obtain consent for sharing customer information?',
    'What should banks do to ensure that the consumer knows about the calculation of finance charges?',
    'What should banks do before reporting the default status of a credit card holder to CIBIL?',
    'What should banks do so that the identity of the person withdrawing cash from the ATM can be established?',
    'How should the request for closure of credit card be treated by the bank ?',
    'List all the circulars from last year.',
    '/help',
    // '/info',
  ];
  
  const rbiSuggestions = [
    '/question How often should IRRBB policies be reviewed?',
    '/question What is the limit for offline payment transactions as of 2024?',
    '/question What is KYC?',
    '/question What is AML?',
    '/question What are the safeguards that banks should take for preventing misuse of lost/stolen cards?',
    '/question List the steps that banks with foreign branches should do to translate their financial statements',
    '/question What should banks do to obtain consent for sharing customer information?',
    '/question What should banks do to ensure that the consumer knows about the calculation of finance charges?',
    '/question What should banks do before reporting the default status of a credit card holder to CIBIL?',
    '/question What should banks do so that the identity of the person withdrawing cash from the ATM can be established?',
    '/question How should the request for closure of credit card be treated by the bank ?',
    '/circular DoR.REG/LIC.No.72/16.05.000/2023-24',
    '/changes A.P. (DIR Series) Circular No. 13',
    '/checklist Master Direction on Information Technology Governance, Risk, Controls and Assurance Practices',
    '/checklist Master Circular – Housing Finance',
    '/references DoR.RET.REC.59/12.01.001/2023-24',
    '/browse',
    '/question',
    'How often should IRRBB policies be reviewed?',
    'What is the limit for offline payment transactions as of 2024?',
    'What is KYC?',
    'What is AML?',
    'What are the safeguards that banks should take for preventing misuse of lost/stolen cards?',
    'List the steps that banks with foreign branches should do to translate their financial statements',
    'What should banks do to obtain consent for sharing customer information?',
    'What should banks do to ensure that the consumer knows about the calculation of finance charges?',
    'What should banks do before reporting the default status of a credit card holder to CIBIL?',
    'What should banks do so that the identity of the person withdrawing cash from the ATM can be established?',
    'How should the request for closure of credit card be treated by the bank ?',
    'List all the circulars from last year.',
    '/help',
    // '/info',
    'List all the circulars from last year.'
  ];
  
  const sebiSuggestions = [
    '/question What is the new requirement for fund managers of commodity-based funds like Gold ETFs and Silver ETFs?',
    '/question What principle must stock exchanges follow when monitoring clients funds with stock brokers?',
    '/question What change has SEBI made regarding the fit and proper text on contract notes?',
    '/question When is the new effective date for top 100 listed entities to verify market rumours?',
    '/question What is a Zero Coupon Zero Principal Instrument?',
    '/question How long is the validity of the accreditation certificate if an applicant meets the eligibility criteria for each of the preceding two financial years?',
    '/question What is the purpose of the "Aggregate Escrow Demat Account"?',
    '/circular SEBI/HO/DDHS/DDHS-PoD-2/P/CIR/2024/108',
    '/references SEBI/HO/DDHS/DDHS-PoD-2/P/CIR/2024/115',
    '/changes SEBI/HO/DDHS/DDHS-PoD-2/P/CIR/2024/108',
    '/checklist Master Circular for Alternative Investment Funds (AIFs)',
    '/checklist Master Circular for Mutual Funds',
    '/browse',
    '/question',
    'What is the new requirement for fund managers of commodity-based funds like Gold ETFs and Silver ETFs?',
    'What principle must stock exchanges follow when monitoring clients funds with stock brokers?',
    'What change has SEBI made regarding the fit and proper text on contract notes?',
    'When is the new effective date for top 100 listed entities to verify market rumours?',
    'What is a Zero Coupon Zero Principal Instrument?',
    'How long is the validity of the accreditation certificate if an applicant meets the eligibility criteria for each of the preceding two financial years?',
    'What is the purpose of the "Aggregate Escrow Demat Account"?',
    'List all the circulars from last year.',
    '/help',
    // '/info',
  ];
  const [regulator, setRegulator] = useState('');
  const [filteredSuggestions, setFilteredSuggestions] = useState(allSuggestions);
    useEffect(() => {
      if (regulator === 'RBI') {
        setFilteredSuggestions(rbiSuggestions);
      } else if (regulator === 'SEBI') {
        setFilteredSuggestions(sebiSuggestions);
      } else if (regulator === 'RBI,SEBI' || regulator === 'SEBI,RBI') {
        setFilteredSuggestions([...rbiSuggestions, ...sebiSuggestions]);
      } else {
        setFilteredSuggestions(allSuggestions);
      }
    }, [regulator]);
    const handleInputChange = (event) => {
      const newText = event.target.value;
      setInputText(newText);
      const filtered = filteredSuggestions.filter((suggestion) =>
        suggestion.toLowerCase().includes(newText.toLowerCase())
      );
  
      setSuggestions(filtered);
      setSuggestionsVisible(newText.trim() !== '' && filtered.length > 0);
  
      if (newText.trim() === '' && messages.length === 0) {
        setChatPlaceholder(placeholderText);
      } else {
        setChatPlaceholder(placeholderText);
      }
    };
    const apiResponseColumns = [

      {
        field: "serialNumber",
        headerName: "Sr. No.",
        renderCell: (params) => params.row.serialNumber,
      },
    
      {
        field: "circularNumber",
        headerName: "Circular Number",
        flex: 2,
        renderCell: (params) => (
          <div title={params.value} style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
            {params.value}
          </div>
        ),
      },
      {
        field: "date",
        headerName: "Date",
        width: 100,
        type: "date",
        valueGetter: (params) => {
          const dateStr = params.value;
          const [day, month, year] = dateStr.split("-");
          return new Date(year, month - 1, day);
        },
      },
      {
        field: "subject",
        headerName: "Subject",
        flex: 1,
        width: 90,
        renderCell: (params) => (
          <div title={params.value} style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
            {params.value}
          </div>
        ),
      },
      { field: "document_type", headerName: "Document Type", flex: 1 },
      {
        field: "regulator",
        headerName: "Regulator",
        flex: 1,
        width: 90,
        renderCell: (params) => (
          <div title={params.value} style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
            {params.value}
          </div>
        ),
      },
    
      ...(regulator !== "RBI" && regulator !== "SEBI" ?  [
        // console.log(regulator),
        {
          field: "upload_status",
          headerName: "Upload Status",
          flex: 1,
          width: 90,
          renderCell: (params) => (
            <div title={params.value} style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
              {params.value}
            </div>
          ),
        },

        {
          field: "checklist_timestamp",
          headerName: "Checklist Timestamp",
          flex: 1,
          width: 90,
          renderCell: (params) => (
            <div title={params.value} style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
              {params.value}
            </div>
          ),
        },

        {
          field: "document_uploader",
          headerName: "Document Uploader",
          flex: 1,
          width: 90,
          renderCell: (params) => (
            <div title={params.value} style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
              {params.value}
            </div>
          ),
        }
      ] : []),  
    ];
    
    const transformApiResponse = (apiResponse) => {
      return apiResponse.map((item, index) => ({
        id: item.id,
        serialNumber: index + 1,
        circularNumber: item.meta.table_circular_number || item.meta.circular_number || item.meta.subject || "NA",
        date: item.meta.date,
        subject: item.meta.subject,
        document_type: item.meta.regulator_document_type,
        regulator: item.regulator,
        upload_status: item.meta.status || "-",
        checklist_timestamp: item.meta.checklist_timestamp || "-",
        document_uploader: item.meta.document_uploader || "-"
      }));
    };

  const currentData = transformApiResponse(apiResponse);

  class DzUiComponent {

    constructor() {
      if (this.constructor === DzUiComponent) {
        throw new Error("Abstract classes can't be instantiated.");
      }
    }

    transform(response) {
      throw new Error("Not implemented");
    }

  }

  class DzChangesUiComponent extends DzUiComponent {
    constructor() {
      super();
    }
    transform(response) {
      const graphData_unparsed = response['content'];
      let botResponse = "";
      if (graphData_unparsed.asstresponse === "Error: No context provided.") {
        botResponse = setBotResponse(graphData_unparsed.asstresponse);
      } else if (graphData_unparsed.asstresponse.includes("No changes found")) {
        botResponse = setBotResponse(`No changes for this document`);
      } else {
        try {
          const graphData = JSON.parse(graphData_unparsed.asstresponse);
          const title = `Changes made by Circular No. ${graphData.circular[0].name}`
          const graphComponent = <Graph title={title} jsonData={graphData} />;
          botResponse = setBotResponse(graphComponent, true);
        } catch (error) {
          botResponse = setBotResponse(`No changes for this document`);
        }
      }
      setMessages((prevMessages) => [...prevMessages, botResponse]);
      setLoading(false);
    }
  }

  class DzActionUiComponent extends DzUiComponent {
    constructor() {
      super();
      this.handleSubmit = this.handleSubmit.bind(this);
      this.toggleFormVisibility = this.toggleFormVisibility.bind(this);
      this.isFormVisible = false;
    }

    // let isFormVisible = false;
    toggleFormVisibility = () => {
      this.isFormVisible = !this.isFormVisible;
      const formContainer = document.querySelector('.form-container');
      if (formContainer) {
        formContainer.style.display = this.isFormVisible ? 'block' : 'none';
      }
    };
    
    handleSubmit = async (event) => {
      event.preventDefault();
      const actionStatus = event.target.elements.actionStatus.value;
      const comment = event.target.elements.comment.value;
      if (actionStatus === 'select-action' || comment.trim() === '') {
        window.alert("Please select action status and provide a comment.");
        return;
      }
      try {
        const sessionToken = document.cookie.replace(
          /(?:(?:^|.*;\s*)sessionToken\s*=\s*([^;]*).*$)|^.*$/,
          '$1'
        ); 
        const response = await fetch('/add/circular_action_status', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Token': sessionToken, 
          },
          body: JSON.stringify({
            circular_number: this.actionCirculars.cir_num,
            action_status: actionStatus,
            comment: comment,
          }),
        });

        if (!response.ok) {
          throw new Error('Failed to insert data');
        }
        event.target.reset();
        const responseData = await response.json();
        window.alert("Your submission was successful!");
        this.toggleFormVisibility();
      } catch (error) {
        console.error('Error:', error);
      }
    };

    transform(response) {
      this.actionCirculars = response['content'];
      const cir_num = this.actionCirculars['cir_num'];
      const summary = this.actionCirculars["summary"];
      const action_required = this.actionCirculars["action"];
      const previous_deadline = this.actionCirculars["prevdeadline"];
      const new_deadline = this.actionCirculars["newdeadline"];
      const actionTable = (
        <table className="compliance-table" style={{ borderCollapse: 'collapse', width: '95%' }}>
          <thead>
            <tr style={{ backgroundColor: '#f2f2f2' }}>
              <th style={{ padding: '8px', textAlign: 'center', borderBottom: '1px solid #ddd' }}>Action Required</th>
              <th style={{ padding: '8px', textAlign: 'center', borderBottom: '1px solid #ddd' }}>Previous Deadline</th>
              <th style={{ padding: '8px', textAlign: 'center', borderBottom: '1px solid #ddd' }}>New Deadline</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td style={{ padding: '8px', borderBottom: '1px solid #ddd' }}>{action_required}</td>
              <td style={{ padding: '8px', borderBottom: '1px solid #ddd' }}>{previous_deadline}</td>
              <td style={{ padding: '8px', borderBottom: '1px solid #ddd' }}>{new_deadline}</td>
            </tr>
          </tbody>
        </table>
      );

      let botResponse = {
        text: (
          <div>
            <h3 style={{ color: '#333' }}>{cir_num}</h3>
            <p style={{ marginBottom: '20px' }}> <strong>Summary: </strong> {summary}</p>
            {actionTable}
            <img
              src={process.env.PUBLIC_URL + '/chatbot.png'}
              alt="Comment"
              className='comment-btn'
              onClick={this.toggleFormVisibility}
            />
            <form onSubmit={(event) => this.handleSubmit(event)} className="form-container" style={{ display: 'none' }}>
              <div className="form-action">
                <label htmlFor="actionStatus">Action Status:</label>
                <select name="actionStatus" id="actionStatus" className="action-status-dropdown">
                  <option value="select-action">Select Action Status</option>
                  <option value="completed">Completed</option>
                  <option value="incomplete">Incomplete</option>
                </select>
              </div>
              <div className="form-comment">
                <label htmlFor="comment">Comment:</label>
                <textarea id="comment" name="comment" className="comment-input" rows="4" cols="50" placeholder="Add your comment here..."></textarea>
              </div>
              <button type="submit" className="form-submit-button">Submit</button>
            </form>

          </div>
        ),
        isTable: true,
      };
      setMessages((prevMessages) => [...prevMessages, botResponse]);
      setLoading(false);
    }
  }

  class DzTextUiComponent extends DzUiComponent {
    constructor() {
      super();
    }
    async transform(res) {
      try {
        const content = res['content'];
        const asstResponse = content['asstresponse'];


        let botResponse = {};
        if (asstResponse.includes('<script')) {
          const scriptUrlRegex = /<script.*?src=["'](.*?)["']/;
          const match = asstResponse.match(scriptUrlRegex);
          if (match) {
            const scriptUrl = match[1];
            const proxyUrl = 'https://cors.bridged.cc/';
            const response = await fetch(proxyUrl + scriptUrl);
            const scriptContent = await response.text();

            botResponse = {
              text: (
                <span>
                  <ReactMarkdown>{scriptContent}</ReactMarkdown>
                </span>
              ),
            };
          } else {
            console.error('Script URL not found in the response');
            botResponse = {
              text: (
                <span>
                  {/* <div dangerouslySetInnerHTML={{ __html: asstResponse }} /> */}
                  <ReactMarkdown>{asstResponse}</ReactMarkdown>
                </span>
              ),
            };
          }
        } else {
          console.error("Gist file not found");
          botResponse = {
            text: (
              <span>
                {/* <div dangerouslySetInnerHTML={{ __html: asstResponse }} /> */}
                <ReactMarkdown>{asstResponse}</ReactMarkdown>
              </span>
            ),
          };
        }
        setMessages((prevMessages) => [...prevMessages, botResponse]);
        setLoading(false);
      } catch (error) {
        console.error("Error in transforming search UI component:", error);
        return setBotResponse("Error: Failed to process response data.");
      }
    }


  }
  class DzReferencesUiComponent extends DzUiComponent {
    constructor() {
      super();
    }
    transform(response) {
      try {
        const graphData_unparsed = response['content'];
        let botResponse;
        if (graphData_unparsed.asstresponse === "Error: No context provided.") {
          botResponse = setBotResponse(graphData_unparsed.asstresponse);
        } else if (graphData_unparsed.asstresponse.includes("No relationships for this document")) {
          botResponse = setBotResponse(`No references for this document`);
        } else {
          const graphData = JSON.parse(graphData_unparsed.asstresponse);
          const title = `References for Circular No. ${graphData.circular[0].name}`
          const graphComponent = <Graph title={title} jsonData={graphData} />;
          botResponse = setBotResponse(graphComponent, true);
        }
        setMessages((prevMessages) => [...prevMessages, botResponse]);
        setLoading(false);
        return botResponse;
      } catch (error) {
        return setBotResponse(`No references for this document`);
      }
    }
  }

  class DzSearchUiComponent extends DzUiComponent {
    constructor() {
      super();
    }

    transform(res) {
      try {
        const assistantMessages = res;
        const content = assistantMessages['content'];
        const asstResponse = content['asstresponse'];
        const pdfLinks = content['pdf_link'];
        const pageNumbers = content['page_num'];
        const distances = content['distance'];
        const pdfNames = content['pdf_name'] || [];
        const docIds = content['doc_id']
        const QA_context = content['qa_context']
        setQAContext(`${QA_context}`)

        let botResponse = {
          text: (
            <span>
              {pdfmodalOpen && <PdfModalPage pdfUrl={selectedPdfUrl} onClose={closePdfModal} />}
              {/* <div dangerouslySetInnerHTML={{ __html: asstResponse }} style={{ whiteSpace: 'pre-wrap' }} /> */}
              <ReactMarkdown>{asstResponse}</ReactMarkdown>
              <br />
              {pdfLinks && pdfLinks.length > 0 && (
                <div>
                  <table className="pdf-table">
                    <thead>
                      <tr>
                        <th>Sr. Number</th>
                        <th>PDF Name</th>
                        <th>Page Number</th>
                        <th>Date</th>
                        <th>Download</th>
                      </tr>
                    </thead>
                    <tbody>
                      {pdfLinks.map((pdfLink, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>
                            <a href={pdfLink} onClick={(e) => openPdfInMinimizedWindow(e, pdfLink)}>
                              {pdfNames[index] || `PDF ${index + 1}`}
                            </a>
                          </td>
                          <td>{pageNumbers[index]}</td>
                          <td>{distances[index]}</td>
                          <td>
                            <a href={pdfLink} download target="_blank" rel="noopener noreferrer">
                              <FontAwesomeIcon icon={faArrowDown} />
                            </a>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
            </span>
          ),
        };

        {
          pdfUrl && (
            <div className="pdf-viewer-container">
              <button onClick={closePdfViewer}>Close PDF Viewer</button>
              <div className="pdf-viewer">
                <Document file={pdfUrl}>
                  <Page pageNumber={1} />
                </Document>
              </div>
            </div>
          )
        }
        setMessages((prevMessages) => [...prevMessages, botResponse]);
        setLoading(false);
      } catch (error) {
        console.error("Error in transforming search UI component:", error);
        return setBotResponse("Error: Failed to process response data.");
      }
    }
  }


  class ParentComponent extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        isPdfViewerOpen: false,
        pdfUrl: null,
      };
    }

    updatePdfViewerState = (isPdfViewerOpen, pdfUrl) => {
      this.setState({ isPdfViewerOpen, pdfUrl });
    };

    render() {
      const { isPdfViewerOpen, pdfUrl } = this.state;

      return (
        <div>
          <DzSearchUiComponent updatePdfViewerState={this.updatePdfViewerState} />
          {isPdfViewerOpen && pdfUrl && (
            <div className="pdf-viewer-container">
              <button onClick={this.closePdfViewer}>Close PDF Viewer</button>
              <div className="pdf-viewer">
                <Document file={pdfUrl}>
                  <Page pageNumber={1} />
                </Document>
              </div>
            </div>
          )}
        </div>
      );
    }
  }
  const handlepdfClick = (pdfUrl) => {
    openPdfInModal(pdfUrl);
  };
  class DzTableUiComponent extends DzUiComponent {
    constructor() {
      super();
    }
    transform(response) {
      const responseData = response;
      const content = responseData['content'];
      const circular_list = content['circular_list'];
      const date = content['date_list'];
      const title = content['title_list'];
      const deadline = content['deadline_list'];
      const recipients = content['recipient_list'];
      const sender = content['sender_list'];
      const pdf_url = content['pdf_url']

      const circularTableData = circular_list.map((circular, index) => ({
        id: index,
        Circular: circular,
        Date: date[index],
        Title: title[index],
        Deadline: deadline[index],
        // Status: status[index],
        Recipients: recipients[index],
        Sender: sender[index],
        Pdf_url: pdf_url[index]
      }));

      const circularTable = (
        <table className="compliance-table">
          <thead>
            <tr>
              <th>Circular</th>
              <th>Date</th>
              <th>Title</th>
            </tr>
          </thead>
          <tbody>
            {circularTableData.map((row, index) => (
              <tr key={index}>
                <td>{row.Circular}</td>
                <td>{row.Date}</td>
                <td>{row.Title}</td>
              </tr>
            ))}
          </tbody>
        </table>
      );
      const CustomToolbar = () => {
        return (
          <div style={{ padding: "10px" }}>
            <GridToolbarExport />
          </div>
        );
      };

      const CustomCell = (params) => (
        <div className="compliance-dropdown">
          <a href="#" onClick={(e) => {
            handleCircularClick2(params.row.Pdf_url);
        
            handleCircularClick(e, params.value, params.row.Date);
          }}>
            {params.value}
          </a>
          <div className="dropdown-content">
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                handleCircularClick(e, params.value, params.row.Date);
                setInputText("/references");
              }}
            >
              What are the documents referred?
            </a>
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                handleCircularClick(e, params.value, params.row.Date);
                setInputText("/changes");
              }}
            >
              What are the documents changed?
            </a>
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                handleCircularClick(e, params.value, params.row.Date);
                setInputText("/checklist");
              }}
            >
              What are the main checklist item(s)?
            </a>
          </div>
        </div>
      );
      let botResponse = {
        text: (
          <div>
            <span>
              Below is the list of circulars that you requested.<br />
              You can navigate through all the circulars from this table. Use any of the following commands to find out more about the circulars: <br />
              <ul>
                <li>/action -- What are the action items mentioned in the circular?</li>
                <li>/references -- What all Circulars/Acts/Regulations are referred in this circular? </li>
                <li>/changes -- What are the changes mentioned in this circular? </li>
              </ul>
            </span>
            <br />
            <span className="headClass">Circulars:</span>
            <div style={{ height: 500, width: "100%" }}>
              <DataGrid
                rows={circularTableData}
                columns={[
                  {
                    field: "id",
                    headerName: "Sr.Number",
                    width: 80,
                    flex: 1,
                    hide: true,
                    height: 100,
                  },
                  {
                    field: "Circular",
                    headerName: "Circular",
                    cellClassName: "circularStyle",
                    renderCell: CustomCell,
                    height: 300,
                    width: 100,
                    flex: 3,
                  },
                  {
                    field: "Date",
                    headerName: "Date",
                    width: 100,
                    type: "date",
                    height: 100,
                    valueGetter: (params) => {
                      const [day, month, year] = params.value.split("-");
                      return new Date(year, month - 1, day);
                    },
                  },
                  {
                    field: "Title",
                    headerName: "Title",
                    flex: 4,
                    width: 100,
                    height: 100,
                  },
                ]}
                pageSize={20}
                pagination={true}
                rowsPerPageOptions={[20, 50, 100]}
                onRowClick={(params) => { }}
                components={{
                  Toolbar: CustomToolbar,
                }}
              />
            </div>
          </div>
        ),
        isTable: true,
      };
      setMessages((prevMessages) => [...prevMessages, botResponse]);
      setLoading(false);
    }
  }
  class DzCircularUiComponent extends DzUiComponent {
    constructor() {
      super();
    }
    transform(response) {
      try {
        const content = response['content'];
        const circularMetadata = content['circular_metadata'];
        const referencesContent = content['references'];
        const changesContent = content['changes'];
        const actionContent = content['action'];
        setQAContext(`${circularMetadata['name']} (${circularMetadata['regulator']})`);
        const circularTable = (
          <div className="circular-metadata">
            <span className="headClasss">Circular Metadata:</span>
            <div>
              <div className="metadata-part">Name:
                <a href={circularMetadata['path']} onClick={(e) => openPdfInMinimizedWindow(e, circularMetadata['path'])}>
                  {circularMetadata['name']}
                </a>
              </div><br />
              <div className="metadata-part">Date: {circularMetadata['date']}</div><br />
              <div className="metadata-part">Subject: {circularMetadata['subject']}</div><br />
              {circularMetadata['summary'] && (
                <>
                  <div className="metadata-part">Summary: {circularMetadata['summary']}</div>
                  <br />
                </>
              )}
            </div>
          </div>
        );

        let botResponse = {
          text: (
            <div className="bot-response">
              {circularTable}
              <div className="button-containerr">
              {regulator === 'RBI' && regulator === 'SEBI' ? (
              <>
                <button className="action-button" onClick={() => this.handleReferencesClick(referencesContent)}>References</button>
                <button className="action-button" onClick={() => this.handleActionClick(actionContent)}>Action</button>
              </>
            ) : null}
                <button className="action-button" onClick={() => this.handleActionClick(actionContent)}>Checklist</button>
              </div>
            </div>
          ),
        };

        setMessages((prevMessages) => [...prevMessages, botResponse]);
        setLoading(false);
      } catch (error) {
        console.error("Error in transforming circular UI component:", error);
        return setBotResponse("Error: Failed to process response data.");
      }

    }

    handleReferencesClick(referencesContent) {
      const referencesUiComponent = new DzReferencesUiComponent();
      referencesUiComponent.transform(referencesContent);
    }

    handleChangesClick(changesContent) {
      const changesUiComponent = new DzChangesUiComponent();
      changesUiComponent.transform(changesContent);
    }

    handleActionClick(actionContent) {
      const actionUiComponent = new DzAuditorUiComponent();
      actionUiComponent.transform(actionContent);
    }
  }
  
  class DzAuditorUiComponent extends DzUiComponent {
    constructor() {
      super();
      this.handleSubmit = this.handleSubmit.bind(this);
      this.toggleFormVisibility = this.toggleFormVisibility.bind(this);
      this.handleDownload = this.handleDownload.bind(this);
      this.isFormVisible = false;
    }

    toggleFormVisibility = () => {
      this.isFormVisible = !this.isFormVisible;
      const formContainer = document.querySelector('.form-container');
      if (formContainer) {
        formContainer.style.display = this.isFormVisible ? 'block' : 'none';
      }
    };

    handleSubmit = async (event) => {
      event.preventDefault();
      const actionStatus = event.target.elements.actionStatus.value;
      const comment = event.target.elements.comment.value;
      if (actionStatus === 'select-action' || comment.trim() === '') {
        window.alert("Please select action status and provide a comment.");
        return;
      }
      try {
        const sessionToken = document.cookie.replace(
          /(?:(?:^|.*;\s*)sessionToken\s*=\s*([^;]*).*$)|^.*$/,
          '$1'
        ); 
        const response = await fetch('/add/circular_action_status', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Token': sessionToken, 
          },
          body: JSON.stringify({
            circular_number: this.actionCirculars.cir_num,
            action_status: actionStatus,
            comment: comment,
          }),
        });

        if (!response.ok) {
          throw new Error('Failed to insert data');
        }

        event.target.reset();
        const responseData = await response.json();
        window.alert("Your submission was successful!");
        this.toggleFormVisibility();
      } catch (error) {
        console.error('Error:', error);
      }
    };
    handleDownload = async (jsonResponse, title) => {
      try {
        const sessionToken = document.cookie.replace(
          /(?:(?:^|.*;\s*)sessionToken\s*=\s*([^;]*).*$)|^.*$/,
          '$1'
        ); 
        const sanitizeFilename = (filename) => {
          return filename.replace(/[<>:"/\\|?*]+/g, '_').trim();
        };    
        const sanitizedTitle = sanitizeFilename(title);
        const response = await fetch('/download_excel', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Token': sessionToken, 
          },
          body: JSON.stringify({ json_output: jsonResponse, title: sanitizedTitle }),
        });
    
        if (!response.ok) {
          throw new Error('Failed to download');
        }
    
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = `${sanitizedTitle}.xlsx`;
        link.click();
        window.URL.revokeObjectURL(url);
    
        window.alert("Download successful!");
      } catch (error) {
        console.error('Error in handleDownload:', error);
        window.alert("Download failed!");
      }
    };

    async transform(res) {
      try {
        const content = res['content'];
        const asstResponse = content['asstresponse'];
        const title = content['title'];
        const pdf_path = content['pdf_path'];
        const json_response = content['json_output'];
        const content_type = res['content_type']; 
        const sanitizedPdfPath = DOMPurify.sanitize(pdf_path);
        const sanitizedasstResponse = DOMPurify.sanitize(asstResponse);
        let botResponse = {};
        if (asstResponse.includes('<script')) {
          const scriptUrlRegex = /<script.*?src=["'](.*?)["']/;
          const match = asstResponse.match(scriptUrlRegex);
          if (match) {
            const scriptUrl = match[1];
            const proxyUrl = 'https://cors.bridged.cc/';
            const response = await fetch(proxyUrl + scriptUrl);
            const scriptContent = await response.text();
            const sanitizedScriptContent = DOMPurify.sanitize(scriptContent);
            botResponse = {
              text: (
                <div className="question-container" style={{ position: 'relative'}}>
                  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <a
                      href={sanitizedPdfPath}
                      onClick={(e) => openPdfInMinimizedWindow(e, sanitizedPdfPath)}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <h2 style={{ color: '#333' }}>{title}</h2>
                    </a>
                    {content_type === 'DzAuditorUiComponent' && json_response !== null && (
                       <div className="floating-download-btn">
                      <button
                        style={{
                          marginLeft: '10px',
                          padding: '10px',
                          color: 'blue',
                          fontSize: '20px',
                          background: 'none',
                          border: 'none',
                          cursor: 'pointer',
                        }}
                        onClick={() => this.handleDownload(json_response, title)}
                        title="Download file"
                      >
                      <img src="/download_checklist.png" alt="download" />

                      </button>
                      </div>
                    )}
                  </div>
                  <span>
                    <div dangerouslySetInnerHTML={{ __html: sanitizedScriptContent }} />
                  </span>
                </div>


              ),
            };
          } else {
            botResponse = {
              text: (
                <div className="question-container" style={{ position: 'relative'}}>
                  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <a
                      href={sanitizedPdfPath}
                      onClick={(e) => openPdfInMinimizedWindow(e, sanitizedPdfPath)}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <h2 style={{ color: '#333' }}>{title}</h2>
                    </a>
                    {content_type === 'DzAuditorUiComponent' && json_response !== null && (
                       <div className="floating-download-btn">
                      <button
                        style={{
                          marginLeft: '10px',
                          padding: '10px',
                          color: 'blue',
                          fontSize: '20px',
                          background: 'none',
                          border: 'none',
                          cursor: 'pointer',
                        }}
                        onClick={() => this.handleDownload(json_response, title)}
                        title="Download file"
                      >
                        <img src="/download_checklist.png" alt="download" />
                      </button>
                      </div>
                    )}
                  </div>
                  <span>
                    <div dangerouslySetInnerHTML={{ __html: sanitizedasstResponse }} />
                    {/* <ReactMarkdown>{asstResponse}</ReactMarkdown> */}
                  </span>
                </div>
              ),
            };
          }
        } else {
          botResponse = {
            text: (
              <div className="question-container" style={{ position: 'relative'}}>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <a
                    href={sanitizedPdfPath}
                    onClick={(e) => openPdfInMinimizedWindow(e, sanitizedPdfPath)}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <h2 style={{ color: '#333' }}>{title}</h2>
                  </a>
                  {content_type === 'DzAuditorUiComponent' && json_response !== null && (
                     <div className="floating-download-btn">
                    <button
                      style={{
                        marginLeft: '10px',
                        padding: '10px',
                        fontSize: '20px',
                        color: 'blue',
                        background: 'none',
                        border: 'none',
                        cursor: 'pointer',
                      }}
                      onClick={() => this.handleDownload(json_response, title)}
                      title="Download file"
                    >
                      <img src="/download_checklist.png" alt="download" />
                    </button>
                    </div>
                  )}
                </div>
                <span>
                  <div dangerouslySetInnerHTML={{ __html: sanitizedasstResponse }} />
                  {/* <ReactMarkdown>{asstResponse}</ReactMarkdown> */}
                </span>
                {/* <img
                  src={process.env.PUBLIC_URL + '/chatbot.png'}
                  alt="Comment"
                  className='comment-btn'
                  onClick={this.toggleFormVisibility}
                /> */}
                <form onSubmit={(event) => this.handleSubmit(event)} className="form-container" style={{ display: 'none' }}>
                  <div className="form-action">
                    <label htmlFor="actionStatus">Action Status:</label>
                    <select name="actionStatus" id="actionStatus" className="action-status-dropdown">
                      <option value="select-action">Select Action Status</option>
                      <option value="completed">Completed</option>
                      <option value="incomplete">Incomplete</option>
                    </select>
                  </div>
                  <div className="form-comment">
                    <label htmlFor="comment">Comment:</label>
                    <textarea id="comment" name="comment" className="comment-input" rows="4" cols="50" placeholder="Add your comment here..."></textarea>
                  </div>
                  <button type="submit" className="form-submit-button">Submit</button>
                </form>
              </div>
            ),
          };
        }
        setMessages((prevMessages) => [...prevMessages, botResponse]);
        setLoading(false);
      } catch (error) {
        console.error("Error in transforming search UI component:", error);
        return setBotResponse("Error: Failed to process response data.");
      }
    }
  }

  const [selectedFiles, setSelectedFiles] = useState([]);
  const handleIconClick = () => {
    setIsPopupOpen(true);
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false);
  };
  
  const pollStatus = async (taskID) => {
      try {
          setIsProcessing(true)
          let isProcessing = true;
          while (isProcessing) {
              // const response = await fetch(`/check_status?task_id=${taskID}`);
              const response = await fetch(`/check_status?task_id=${taskID}`);
              const responseData = await response.json();
              // console.log(responseData)
              // console.log(response)
              // console.log(response.status)
              if (response.status === 200) {
                  const content = responseData['content'];
                  const title = content['title'];
                  // const content_type = responseData['content_type'];
                  // const dzAuditorComponent = UIComponentFactory(content_type);
                  // dzAuditorComponent.transform(responseData); // Render the processed document result
                  alert(`Document ${title} processed successfully.`);
                  await handleBrowseSubmit();
                  setIsProcessing(false)
                  isProcessing = false
              } else if (response.status === 202){
                  // console.log('Still processing...');
                  await new Promise(resolve => setTimeout(resolve, 5000));  
              } else {
                // console.log('Failed!');
                const content_type = responseData['content_type'];
                const dzAuditorComponent = UIComponentFactory(content_type);
                dzAuditorComponent.transform(responseData);
                alert('Document Processing Failed');
                setIsProcessing(false)
                isProcessing = false
              }
          }
      } catch (error) {
          console.error('Error checking status:', error);
          setIsProcessing(false)
          isProcessing = false
      }
  };
  const handleFormSubmit = async (formData) => {
    setLoading(true);
    setBrowseLoading(true);
    try {
        const response = await fetch('/upload_doc', {
            method: 'POST',
            body: formData,
        });

        if (response.status === 202) {
            alert('Upload accepted, processing will complete soon.');
            const responseData = await response.json();
            const taskID = responseData.taskID;
            // const pdfID = responseData.pdf_id;
            // Start polling to check for completion
            await handleBrowseSubmit();
            pollStatus(taskID);
        } else if (response.status === 200){
            alert('Upload accepted, processing will complete soon.');
            const responseData = await response.json();
            const content_type = responseData['content_type'];
            const content = responseData['content'];
            const title = content['title'];
            const dzAuditorComponent = UIComponentFactory(content_type);
            dzAuditorComponent.transform(responseData); 
            alert(`Document ${title} already exists in the database. Switch to prompt window to see the results.`);
        } else if (response.status === 403) {
          toast.error('You don’t have access to this regulator.');
        }else {
            const responseData = await response.json();
            const content_type = responseData['content_type'];
            const dzAuditorComponent = UIComponentFactory(content_type);
            dzAuditorComponent.transform(responseData); 
            alert('Error processing data');
            await handleBrowseSubmit();
        }
    } catch (error) {
        console.error('Error:', error);
        alert('Error submitting data');
    } finally {
      setLoading(false);
      setBrowseLoading(false);
    }
};
  const handleFileChange = async (e) => {
    const files = e.target.files;
    setSelectedFiles([...files]);
    // console.log(files); 
    const formData = new FormData();
    for (let i = 0; i < files.length; i++) {
      formData.append('files', files[i]);
    }

    try {
      const response = await fetch('https://your-api-endpoint.com/upload', {
        method: 'POST',
        body: formData,
      });

      if (!response.ok) {
        throw new Error('Failed to upload files');
      }

      const result = await response.json();
      // console.log('File upload successful:', result);
    } catch (error) {
      console.error('Error uploading files:', error);
    }
  };

  const [circularNumberr, setCirNumber] = useState("");
  const [qaContext, setQAContext] = useState("");

  // const handleSendMessage = async (message = null) => {
  // const originalMessage = typeof message === 'string' ? message.trim() : String(inputText || '').trim();
  // let textToSend = originalMessage; // Display original message to user
  
  //   try {
  //     const sessionToken = document.cookie.replace(
  //       /(?:(?:^|.*;\s*)sessionToken\s*=\s*([^;]*).*$)|^.*$/,
  //       '$1'
  //     );
  
  //   // Modify message for the API payload only
  //     if (textToSend.includes('/browse')) {
  //       textToSend = '/compliance';
  //     } else if (textToSend.toLowerCase().includes('/checklist')) {
  //       textToSend = textToSend.replace(/\/checklist/gi, '/action');
  //     }
  
  //   if (originalMessage !== '') {
  //     console.log(originalMessage)
  //     const userMessage = { text: originalMessage, isUser: true };
  //     console.log(userMessage)
  //       setMessages((prevMessages) => [...prevMessages, userMessage]);
  //       setLoading(true);
  //       setInputText('');
  //       setSuggestionsVisible(false);
  
  //       const response = await fetch('/get_response', {
  //         method: 'POST',
  //         headers: {
  //           'Content-Type': 'application/json',
  //           'X-CSRF-Token': sessionToken,
  //         },
  //       body: JSON.stringify({ user_input: textToSend }), 
  //       });
  
  //       if (response.ok) {
  //         const Response_body = await response.json();
  //         const content = Response_body['content'];
  //         const content_type = Response_body['content_type'];
  //         const Component = UIComponentFactory(content_type);
  //         Component.transform(Response_body);
  //       } else if (response.status === 302) {
  //         const redirectedUrl = response.headers.get('Location');
  //       } else if (response.status === 403) {
  //         // Show toast notification if access is forbidden
  //         toast.error('You don’t have access to this regulator.');
  //       } else {
  //         console.error('Error:', response.statusText);
  //       }
  //     }
  //   } catch (error) {
  //     console.error('Error:', error);
  //   } finally {
  //     setLoading(false);
  //   }
  // };
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);

  useEffect(() => {
    // Fetch super admin status
    axios.get('/rbac/is_super_admin')
      .then(response => {
        setIsSuperAdmin(response.data.is_super_admin);
      })
      .catch(error => {
        console.error('Error fetching super admin status:', error);
      });
  }, []);
  const handleSendMessage = async (message = null) => {
    const originalMessage = typeof message === 'string' ? message.trim() : String(inputText || '').trim();
    let textToSend = originalMessage; // Display original message to user
    
    try {
      const sessionToken = document.cookie.replace(
        /(?:(?:^|.*;\s*)sessionToken\s*=\s*([^;]*).*$)|^.*$/,'$1'
      );
  
      // Modify message for the API payload only
      if (textToSend.includes('/browse')) {
        textToSend = '/compliance';
      } else if (textToSend.toLowerCase().includes('/checklist')) {
        textToSend = textToSend.replace(/\/checklist/gi, '/action');
      }
  
      // Update the userMessage state to use in rendering QA Context
      setUserMessage(originalMessage);
  
      if (originalMessage !== '') {
        // console.log(originalMessage)
        const userMessage = { text: originalMessage, isUser: true };
        // console.log(userMessage)
        setMessages((prevMessages) => [...prevMessages, userMessage]);
        setLoading(true);
        setInputText('');
        setSuggestionsVisible(false);
  
        const response = await fetch('/get_response', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Token': sessionToken,
          },
          body: JSON.stringify({ user_input: textToSend }), 
        });
  
        if (response.ok) {
          const Response_body = await response.json();
          const content = Response_body['content'];
          const content_type = Response_body['content_type'];
          const Component = UIComponentFactory(content_type);
          Component.transform(Response_body);
        } else if (response.status === 302) {
          const redirectedUrl = response.headers.get('Location');
        } else if (response.status === 403) {
          // Show toast notification if access is forbidden
          toast.error('You don’t have access to this regulator.');
        } else {
          console.error('Error:', response.statusText);
        }
      }
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setLoading(false);
    }
  };
  
  function UIComponentFactory(content_type) {
    switch (content_type) {
      case "DzTableUiComponent":
        return new DzTableUiComponent();
        break;
      case "DzReferencesUiComponent":
        return new DzReferencesUiComponent();
        break;
      case "DzActionUiComponent":
        return new DzActionUiComponent();
        break;
      case "DzChangesUiComponent":
        return new DzChangesUiComponent();
        break;
      case "DzCircularUiComponent":
        return new DzCircularUiComponent();
        break;
      case "DzTextUiComponent":
        return new DzTextUiComponent();
        break;
      case "DzAuditorUiComponent":
        return new DzAuditorUiComponent();
        break;
      default:
        return new DzSearchUiComponent();
        break;
    }
  };

  const [pdfUrl, setPdfUrl] = useState(null);

  const openPdfViewer = (e, pdfUrl) => {
    e.preventDefault();
    setPdfUrl(pdfUrl);
  };

  const closePdfViewer = () => {
    setPdfUrl(null);
  };

  const openPdfInMinimizedWindow2 = (pdfUrl) => {
    const screenWidth = window.screen.availWidth;
    const screenHeight = window.screen.availHeight;

    const windowWidthPercentage = 60;
    const windowHeightPercentage = 60;

    const windowWidth = (screenWidth * windowWidthPercentage) / 100;
    const windowHeight = (screenHeight * windowHeightPercentage) / 100;

    const topPosition = screenHeight / 2 - windowHeight / 2;
    const leftPosition = screenWidth / 2 - windowWidth / 2;

    const newWindow = window.open(
      pdfUrl,
      "_blank",
      `width=${windowWidth}, height=${windowHeight}, top=${topPosition}, left=${leftPosition}`
    );

    if (newWindow) {
      newWindow.focus();
    }
  };


  const openPdfInMinimizedWindow = (e, pdfUrl) => {
    e.preventDefault();


    const screenWidth = window.screen.availWidth;
    const screenHeight = window.screen.availHeight;


    const windowWidthPercentage = 60;
    const windowHeightPercentage = 60;

    const windowWidth = (screenWidth * windowWidthPercentage) / 100;
    const windowHeight = (screenHeight * windowHeightPercentage) / 100;


    const topPosition = screenHeight / 2 - windowHeight / 2;
    const leftPosition = screenWidth / 2 - windowWidth / 2;

    const newWindow = window.open(
      pdfUrl,
      "_blank",
      `width=${windowWidth}, height=${windowHeight}, top=${topPosition}, left=${leftPosition}`
    );

    if (newWindow) {
      newWindow.focus();
    }
  };

  const parseDate = (dateString) => {
    if (dateString.includes('-')) {
      const [day, month, year] = dateString.split('-');
      return new Date(`${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`);
    } else {
      return parseInt(dateString);
    }
  };
  const handleCircularClick2 = (Pdf_url) => {
    openPdfInMinimizedWindow2(Pdf_url)
  };

  const handleCircularClick = (event, circularName, circularDate) => {
    event.preventDefault();

    fetch('/update-session', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ circularName, circularDate }),
    })
      .then(response => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then(data => {

      })
      .catch(error => {
        console.error('There was a problem with your fetch operation:', error);
      });
  };

  const determineGroup = (doc) => {
    const label = doc.Nodelabel[0];

    if (label.toLowerCase().includes('circular')) {
      return 'group3';
    } else if (label.toLowerCase().includes('notification')) {
      return 'group3';
    } else if (label.toLowerCase().includes('act')) {
      return 'group2';
    } else if (label.toLowerCase().includes('direction')) {
      return 'group1';
    } else {
      return 'group0'
    }
  };

  const Graph = ({ jsonData, title, sendMessage }) => {
    const graphRef = useRef(null);

    useEffect(() => {
      const menu = document.querySelector(".custom-menu");
      const transformData = (data) => {
        const nodes = [];
        const edges = [];
        if (data.circular && data.circular.length > 0) {
          nodes.push({
            id: data.circular[0].name,
            label: data.circular[0].name,
            title: `${data.circular[0].CurrSubject} - Date: ${data.circular[0].date}`,
            norm: data.circular[0].norm_name,
            date: data.circular[0].date,
            pdf_url: data.circular[0].path,
            pdf_id: data.circular[0].pdf_id,
            group: 'group3',
            shape: 'dot',
            font: {
              color: 'black'
            },
            physics: false
          });
          let nodeid = 0
          data.referredDocuments.forEach((doc, index) => {
            const groupId = determineGroup(doc);
            const uniqueId = doc.name + nodeid++;
            nodes.push({
              id: uniqueId,
              docid: doc.name,
              label: doc.name,
              norm: doc.norm_name,
              date: doc.date,
              pdf_url: doc.path,
              pdf_id: doc.pdf_id,
              title: `${doc.ReferredSubject} - Date: ${doc.date}`,
              shape: 'box',
              group: groupId,
              margin: 20,
              fixedsize: true,
            });

            let edge = {
              from: data.circular[0].name,
              to: uniqueId,
              label: data.relationship
            };

            if (data.relationship === 'refers to') {
              edge.title = doc.explanation;
            }

            edges.push(edge);
          });
        }

        return { nodes: new DataSet(nodes), edges: new DataSet(edges) };
      };

      const { nodes, edges } = transformData(jsonData);
      const data = { nodes, edges };
      const options = {
        groups: {
          group1: {
            color: { background: '#ef898e', border: 'black' },
          },
          group2: {
            color: { background: '#80f5a0', border: 'black' },
          },
          group3: {
            color: { background: '#8ed8f3', border: 'black' }
          },
          group0: {
            color: { background: '#FFFFED', border: 'black' }
          }
        },
        nodes: {
          font: {
            color: 'black',
            face: 'Roboto',
          },
          size: 40,
          borderWidth: 2,
          shadow: true,
          shapeProperties: {
            useBorderWithImage: true
          }
        },
        edges: {
          width: 2,
          shadow: true,
          color: {
            color: '#848484',
            highlight: '#848484',
            hover: '#848484',
          },
          font: {

            face: 'Roboto',
          },
          arrows: {
            to: { enabled: true, scaleFactor: 1, type: 'arrow' }
          }
        },
        layout: {
          improvedLayout: true,
          hierarchical: {
            enabled: false
          }
        },
        physics: {
          barnesHut: {
            gravitationalConstant: -50000,
            centralGravity: 0,
            springLength: 200
          },
          maxVelocity: 10,
          minVelocity: 0,
          wind: {
            x: 0,
            y: 0
          }
        },
        interaction: {
          hover: true,
          tooltipDelay: 200,
          hoverConnectedEdges: true
        }
      };

      const network = new Network(graphRef.current, data, options);
      network.stabilize();

      network.on("hoverEdge", function (params) {
        var edgeId = params.edge;
        var edgeData = network.body.edges[edgeId];
        if (edgeData.options.label === 'makes changes to') {
          network.canvas.body.container.style.cursor = 'pointer';
        }
      });
      network.on("blurEdge", function () {
        network.canvas.body.container.style.cursor = 'default';
      });

      network.on("click", function (params) {
        if (params.nodes.length > 0) {
          const nodeId = params.nodes[0];
          const pdf_open = nodes.get(nodeId).pdf_url
          handleNodeClick(pdf_open);
        } else if (params.edges.length > 0) {
          var edgeId = params.edges[0];
          var edge = network.body.edges[edgeId];
          var edgeLabel = edge.options.label;
          if (edgeLabel === "makes changes to") {
            var startNodeId = edge.from;
            var endNodeId = edge.to;
            var startNode = network.body.data.nodes.get(startNodeId.id);
            var endNode = network.body.data.nodes.get(endNodeId.id);
            // var data = {
            //   startNode: startNode.norm,
            //   startNodeDate: startNode.date,
            //   endNode: endNode.norm,
            //   endNodeDate: endNode.date
            // };
            var data = {
              startNode: startNode.pdf_id,
              endNode: endNode.pdf_id
            }
            fetch('/explanation', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json'
              },
              body: JSON.stringify(data)
            })
              .then(response => response.json())
              .then(data => {
                setModalData(data);
                setModalOpen(true);
              })
              .catch((error) => {
                console.error('Error:', error);
              });
          }
        }
      });

      network.on("oncontext", function (params) {
        params.event.preventDefault();
        const nodeId = network.getNodeAt(params.pointer.DOM);
        const docid = nodes.get(nodeId).norm;
        const nodeDate = nodes.get(nodeId).date;
        if (nodeId) {
          handleCircularClick(params.event, docid, nodeDate);
          menu.style.display = 'block';
          const x = params.event.clientX;
          const y = params.event.clientY;
          menu.style.top = `${y}px`;
          menu.style.left = `${x}px`;
        }
      });

      document.getElementById('menu-option1').addEventListener('click', function () {
        setInputText('/references')
        menu.style.display = 'none';
      });

      document.getElementById('menu-option2').addEventListener('click', function () {
        setInputText('/changes')
        menu.style.display = 'none';
      });

      document.getElementById('menu-option4').addEventListener('click', function () {
        setInputText('/action')
        menu.style.display = 'none';
      });

      document.addEventListener('click', function (event) {
        if (event.target.closest('.custom-menu') === null) {
          menu.style.display = 'none';
        }
      });


    }, [jsonData, title, sendMessage]);

    return (
      <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'flex-start', height: '100%', width: '98%', transform: "none", transition: "none", overflow: "hidden" }}>
        {title && <h2 style={{ marginBottom: '20px' }}>{title}</h2>}
        <div ref={graphRef} style={{ width: '100%', height: '600px', paddingBottom: '20px' }} />
        <div class="custom-menu">
          <p id="menu-option1">/references</p>
          <p id="menu-option2">/changes</p>
          <p id="menu-option4">/action</p>
        </div>
        <div style={{ backgroundColor: '#ffffff', padding: '10px', paddingTop: '0px', borderRadius: '5px', boxShadow: '0 0 5px rgba(0, 0, 0, 0.3)', marginBottom: '20px', marginLeft: '20px', alignSelf: "flex-start" }}>
          <h3>Legend</h3>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
              <div style={{ width: '20px', height: '20px', backgroundColor: '#8ed8f3', border: '1px solid black', marginRight: '5px' }}></div>
              <span>Circular</span>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
              <div style={{ width: '20px', height: '20px', backgroundColor: '#80f5a0', border: '1px solid black', marginRight: '5px' }}></div>
              <span>Act</span>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
              <div style={{ width: '20px', height: '20px', backgroundColor: '#ef898e', border: '1px solid black', marginRight: '5px' }}></div>
              <span>Master Direction</span>
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div style={{ width: '20px', height: '20px', backgroundColor: '#FFFFED', border: '1px solid black', marginRight: '5px' }}></div>
              <span>Other Notifications</span>
            </div>
          </div>
        </div>
      </div>


    );
  };

  const handleNodeClick = (pdf_url) => {
    if (pdf_url) {
      openPdfInMinimizedWindow2(pdf_url);
    }
  };

  useEffect(() => {
    const autoclickElement = document.getElementById('autoclick');
    if (autoclickElement) {
      autoclickElement.click();
    }
  }, []);

  const mouseClickEvents = ['mousedown', 'click', 'mouseup'];
  function simulateMouseClick(element) {
    mouseClickEvents.forEach(mouseEventType =>
      element.dispatchEvent(
        new MouseEvent(mouseEventType, {
          view: window,
          bubbles: true,
          cancelable: true,
          buttons: 1
        })
      )
    );
  }

  function convertToDot(data) {
    let dot = `digraph G {
      node [shape=rectangle, color="#F0F8FF", fontname="Helvetica"];
      edge [color="#A9A9A9", fontname="Helvetica"];
      "${data.circular[0].name}" [label=<<TABLE BORDER="0" CELLBORDER="0" CELLSPACING="0"><TR><TD HREF="file:/home/ubuntu/pdf/0.pdf">${data.circular[0].name}</TD></TR></TABLE>>, style=filled, color=lightblue, tooltip="${data.circular[0].CurrSubject + ' - Date: ' + data.circular[0].date}"];\n`;

    for (let doc of data.referredDocuments) {
      dot += `    "${doc.document}" [label=<<TABLE BORDER="0" CELLBORDER="0" CELLSPACING="0"><TR><TD HREF="www.google.com">${doc.document}</TD></TR></TABLE>>, tooltip="${doc.ReferredSubject + ' - Date: ' + doc.date}", style=filled] \n`;
      dot += `    "${data.circular[0].name}" -> "${doc.document}" [label="${data.relationship}", labeltooltip="${doc.specificPart}", headtooltip="${doc.explanation}"]\n`;
    }

    dot += '}';
    return dot;
  }

  function setBotResponse(content, isGraph = false) {
    return {
      text: (

        <div style={{
          border: '1px solid #ddd',
          borderRadius: '4px',
          overflow: 'hidden',
          boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
          maxWidth: '90vw',
          width: '100%',
          margin: '20px auto',
          padding: '10px',
          backgroundColor: '#fff',
        }}>
          {content}
        </div>
      ),
      isGraph: isGraph,
    };
  }

  const handleInputKeyDown = (event) => {
    if (event.key === 'Enter') {
      if (suggestionsVisible && selectedSuggestionIndex !== -1) {
        event.preventDefault();
        handleSuggestionClick(suggestions[selectedSuggestionIndex]);
        setSuggestionsVisible(false);
        setSelectedSuggestionIndex(-1);
      } else {
        handleSendMessage();
      }
    } else if (event.key === 'ArrowUp') {
      event.preventDefault();
      setSelectedSuggestionIndex((prevIndex) => Math.max(0, prevIndex - 1));
    } else if (event.key === 'ArrowDown') {
      event.preventDefault();
      setSelectedSuggestionIndex((prevIndex) => Math.min(suggestions.length - 1, prevIndex + 1));
    }
  };
  const [isSavingChat, setIsSavingChat] = useState(false);

  const handleNewChat = async () => {
    try {
      if (messages.length > 0) {
        setIsSavingChat(true);
        let saveChatResponse = null;
  
        if (activeChatIndex !== null) {
          if (Number.isInteger(activeChatIndex) && activeChatIndex >= 0 && activeChatIndex < chatHistory.length) {
            const updatedHistory = [...chatHistory];
            const chatAtIndex = updatedHistory[activeChatIndex];
            if (chatAtIndex && typeof chatAtIndex === 'object' && chatAtIndex !== null && 
                typeof chatAtIndex.id === 'string' && typeof chatAtIndex.name === 'string') {
              const updatedChat = {
                id: chatAtIndex.id,
                name: chatAtIndex.name,
                messages: [...messages]
              };
  
              const sanitizedMessages = updatedChat.messages.map(message => ({
                ...message,
              }));
  
              saveChatResponse = await fetch('/save_chat', {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                  chatId: updatedChat.id,
                  chatName: updatedChat.name,
                  messages: sanitizedMessages,
                }),
              });
            } else {
              console.error('Invalid chatAtIndex:', chatAtIndex);
              throw new Error('Invalid chat object');
            }
          } else {
            console.error('Invalid activeChatIndex:', activeChatIndex);
            throw new Error('Invalid active chat index');
          }
        } else {
          const firstUserMessage = messages.find((message) => message.isUser);
          const chatName = firstUserMessage ? String(firstUserMessage.text) : "New Chat";
          const newChat = { name: chatName, messages: [...messages] };
          if (typeof newChat === 'object' && newChat !== null && 
              typeof newChat.name === 'string' && Array.isArray(newChat.messages)) {
            const sanitizedMessages = newChat.messages.map(message => ({
              ...message,
            }));
  
            saveChatResponse = await fetch('/save_chat', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({ chat: sanitizedMessages }),
            });
            setChatHistory([newChat, ...chatHistory]);
          } else {
            console.error('Invalid newChat:', newChat);
            throw new Error('Invalid chat object');
          }
        }
  
        if (saveChatResponse && saveChatResponse.ok) {
          const savedChatData = await saveChatResponse.json();
  
          if (savedChatData && typeof savedChatData.chatId === 'string') {
            await fetch(`/get_chatnames?chatId=${savedChatData.chatId}`, {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
              },
            });
          }
  
          alert('Chat saved successfully!');
        } else {
          const errorText = saveChatResponse ? await saveChatResponse.text() : 'Unknown error';
          console.error('Error in save_chat:', errorText);
        }
  
        setIsSavingChat(false);
        setResponseData(null);
        setMessages([]);
        setActiveChatIndex(null);
        setChatPlaceholder(placeholderText);
      } else {
        setMessages([]);
        setResponseData(null);
        setActiveChatIndex(null);
        setChatPlaceholder(placeholderText);
      }
    } catch (error) {
      setIsSavingChat(false);
      console.error('Error in handleNewChat:', error);
    }
  };

  useEffect(() => {
    const apiUrl = '/get_chatnames';
    fetch(apiUrl)
      .then((response) => response.json())
      .then((data) => {

        if (Array.isArray(data.chats)) {
          setChatHistory(data.chats);

        } else {
          console.error('Invalid data format received from the API');
        }
      })
      .catch((error) => {
        console.error('Error fetching chat names:', error);
      });
  }, []);

  const handleChatHistoryClick = async (index) => {
    setActiveChatIndex(index);
    setLoading(true);
    setResponseData(null);
    if (index >= 0 && index < chatHistory.length) {
      const selectedChat = chatHistory[index];
      const chatId = selectedChat.id;
      const chatName = selectedChat.name;

      try {
        const response = await fetch('/get_chatnames_api', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ chatId, chatName }),
        });

        if (response.ok) {
          const secondApiResponse = await fetch('/get_messages', {
            method: 'GET',
          });

          if (secondApiResponse.ok) {
            const responseData = await secondApiResponse.json();

            if (responseData === null || responseData.length === 0) {
              setResponseData([{ isSystemMessage: true, text: 'Chat history is not present for the selected chat.' }]);
              setLoading(false);
            } else {
              setResponseData(responseData);
              setLoading(false);
            }
          } else {
            console.error('Error fetching response from the second API.');
          }
        } else {
          console.error('Error sending chat name to the first API.');
        }
      } catch (error) {
        console.error('Error handling chat history click:', error);
      }
    }

    setChatPlaceholder(placeholderText);
    setLoading(false);
  };


  const handleDeleteChat = async (index, event) => {
    event.stopPropagation();
    const updatedHistory = [...chatHistory];
    const chatToDelete = updatedHistory[index];
    updatedHistory.splice(index, 1);
    setChatHistory(updatedHistory);

    if (activeChatIndex === index) {
      setMessages([]);
      setActiveChatIndex(null);
      setResponseData([]);
      setChatPlaceholder(placeholderText);
    }

    try {
      const deleteChatResponse = await fetch('/delete_chat', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          chatname: chatToDelete.name,
          chatid: chatToDelete.id,
        }),
      });
    }
    catch (error) {
      console.error('Error deleting chat:', error);
    }

  };
  const openPdfInMinimizedWindow3 = (htmlContent) => {
    const screenWidth = window.screen.availWidth;
    const screenHeight = window.screen.availHeight;

    const windowWidthPercentage = 60;
    const windowHeightPercentage = 60;

    const windowWidth = (screenWidth * windowWidthPercentage) / 100;
    const windowHeight = (screenHeight * windowHeightPercentage) / 100;

    const topPosition = screenHeight / 2 - windowHeight / 2;
    const leftPosition = screenWidth / 2 - windowWidth / 2;

    const newWindow = window.open(
      '',
      '_blank',
      `width=${windowWidth}, height=${windowHeight}, top=${topPosition}, left=${leftPosition}`
    );

    // if (newWindow) {
    //   newWindow.focus();
    //   newWindow.document.write(htmlContent);
    //   newWindow.document.close();
    // }
     if (newWindow) {
      newWindow.focus();
      const sanitizedHtmlContent = DOMPurify.sanitize(htmlContent);

    newWindow.document.write(sanitizedHtmlContent);
      // newWindow.document.write(htmlContent);
      newWindow.document.close();
    }
  };

  const handleUserOptionClick = async (option) => {
    switch (option) {
      case 'Logout':
        try {
          // Fetch the authentication type
          const authTypeResponse = await fetch('/logout_type', {
            method: 'POST',
            credentials: 'include',
          });
          if (!authTypeResponse.ok) {
            throw new Error('Failed to get authentication type');
          }

          const authTypeData = await authTypeResponse.json();
          const authType = authTypeData.authentication_type;
          let logoutUrl;
          if (authType === 'Microsoft') {
            logoutUrl = '/microsoft/logout';
          } else if (authType === 'Google') {
            logoutUrl = '/google/logout';
          } else {
            throw new Error('Unknown authentication type');
          }

          const response = await fetch(logoutUrl, {
            method: 'GET',
            credentials: 'include',
          });
          if (response.ok) {
            window.location.href = '/';
          } else {
            console.error('Logout failed:', response);
          }
        } catch (error) {
          console.error('There was an error logging out', error);
        }
        break;
      case 'Generate Token':
        fetch('/generate_token', {
          method: 'POST',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({})
        })
          .then(response => response.json())
          .then(data => {
            const token = data.token;
            let htmlContent = `
                  <!DOCTYPE html>
                  <html lang="en">
                  <head>
                      <meta charset="UTF-8">
                      <meta name="viewport" content="width=device-width, initial-scale=1.0">
                      <title>Token</title>
                      <style>
                          body {
                              font-family: 'Manrope';
                              margin: 0;
                              padding: 20px;
                              background-color: #f8f9fa;
                          }
                          h1 {
                              margin-top: 0;
                          }
                      </style>
                  </head>
                  <body>
                      <h1>Token</h1>
                      <p>${token}</p>
              `;

            if (token !== "Unable to generate token. Please try again.") {
              htmlContent += `
                      <p>Please copy this token as it won't be visible afterwards.</p>
                  `;
            }

            htmlContent += `
                  </body>
                  </html>
              `;

            openPdfInMinimizedWindow3(htmlContent);
          })
          .catch(error => {
            console.error('Failed to generate token:', error);
          });
        break;

      case 'Prompt':
        setIsDarkMode(true)
        break;
      
      case 'Admin':
        window.location.href = '/admin';
        break;

      case 'Browse':
        setIsDarkMode(false);
        break;

      // case 'Multiple Regulator':
      //   setIsUserProfileDropdownOpen(false);
      //   setShowRegulatorForm(true);
      //   break;

      default:
        break;
    }
  };
  const toggleDarkMode = () => {
    setIsDarkMode((prevMode) => {
      if (!prevMode) {
        handleBrowseSubmit(); 
      }
      return !prevMode; 
    });
  
    // console.log(isDarkMode ? 'Switched to Prompt' : 'Switched to Browse');
  };
  
  const handleDocumentClick = (event) => {
    if (event.target.closest('.user-profile-container') === null) {
      setIsUserProfileDropdownOpen(false);
    }
  };

  document.addEventListener('click', handleDocumentClick);

  const handleComponentUnmount = () => {
    document.removeEventListener('click', handleDocumentClick);
  };

  useEffect(() => {
    return handleComponentUnmount;
  }, []);


  const handleDocumentClick1 = (event) => {
    if (!inputRef.current.contains(event.target)) {
      setSuggestionsVisible(false);
    }
  };

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const handleStartDateChange = (date) => {
    setStartDate(date);
    if (!endDate) {
      setEndDate(new Date());
    }
  };
  const handleEndDateChange = date => {
    setEndDate(date);
  };

  const handleInputClick = () => {
    setSuggestionsVisible(true);
  };

  useEffect(() => {
    if (!showRegulatorForm && !isDropdownOpen) {
      scrollToBottom();
    }
  }, [showRegulatorForm, isDropdownOpen]);
  
  const scrollToBottom = () => {
    if (showRegulatorForm || isDropdownOpen) return;
  
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  };
  const handleLogout = () => {
    window.location.href = '/logout';
  };

  const toggleLogout = () => {
    setIsLogoutVisible(!isLogoutVisible);
  };

  const handleSuggestionClick = (suggestion) => {
    handleSendMessage(suggestion);
    setSuggestionsVisible(false);
    suggestionIndexRef.current = -1;

  };

  const [circularCount, setCircularCount] = useState(0);
  const [masterCircularCount, setMasterCircularCount] = useState(0);
  const [masterDirectionCount, setMasterDirectionCount] = useState(0);
  const [latestCircular, setLatestCircular] = useState('');
  const [latestCircularDate, setLatestCircularDate] = useState('');
  const [metadata, setMetadata] = useState({});

  useEffect(() => {
    async function fetchRegulator() {
      try {
        const response = await axios.get('/get_regulator');
        const data = response.data;
        setRegulator(data.regulator);
        setMetadata(data.metadata);
        setQAContext(data.regulator === 'CUSTOM' ? 'internal guidelines' : `${data.regulator} all documents`);
        // setQAContext(`${data.regulator} all documents`)
      } catch (error) {
        console.error('Error fetching regulator:', error);
      }
    }
    fetchRegulator();
  }, []);
  const [showModal, setShowModal] = useState(false);

  const renderCountValue = (key) => {
    return metadata[key]?.count || 0;
  };

  const renderLatestValue = (key) => {
    return metadata[key] || 'N/A';
  };

  const formatDate = (dateString) => {
    const [day, month, year] = dateString.split('-');
    const formattedDate = new Date(`${year}-${month}-${day}`);
    const options = { day: 'numeric', month: 'long', year: 'numeric' };
    return formattedDate.toLocaleDateString('en-GB', options);
  };

  const renderMetadataRows = () => {
    const rows = [];
    const displayRegulator = regulator === 'CUSTOM' ? 'INTERNAL GUIDELINES' : regulator;
  
    // Extract document name if message starts with /circular
    let qaContextValue = regulator;
    if (typeof userMessage === 'string' && userMessage.startsWith('/circular')) {
      const documentName = userMessage.split('/circular')[1]?.trim();
      qaContextValue = documentName ? `QA Context: ${documentName}` : qaContextValue;
    } else {
      qaContextValue = `${regulator}: All documents`; // Default if not /circular
    }
    
  
    // Add top row with Prompt and QA Context
    const firstRow = (
      <div key="top-row" className="metadata-pair">
        <span className="key1"> Possible candidate is browse context: </span>
        <span className="value">{regulator}</span>
        <span className="key1">QA Context: </span>
        <span className="value">{qaContextValue}</span>
      </div>
    );
  
    rows.push(
      <div key="first-row" className="row">
        {firstRow}
        <button
          className="toggle-link"
          onClick={() => setShowMore(!showMore)}
        >
          {showMore ? 'Less...' : 'More...'}
        </button>
      </div>
    );

    if (showMore) {
      rows.push(
        <div key="summary-section" className="row-summary">
           <h3>Summary of Documents</h3>
          <div className='summary-section'>
          <div className="section">
            <h4>Count of Documents</h4>
            <div className='table-row1'>
            <div className="metadata-pair-count">
              <span className="key">Circulars:</span>
              <span className="value">{renderCountValue('Circulars')}</span>
            </div>
            <div className="metadata-pair-count">
              <span className="key">Master Circulars:</span>
              <span className="value">{renderCountValue('Master_Circulars')}</span>
            </div>
            <div className="metadata-pair-count">
              <span className="key">Master Directions:</span>
              <span className="value">{renderCountValue('Master_Circulars')}</span>
            </div>
            </div>
            <div className='table-row2'>
            <div className="metadata-pair-count">
              <span className="key">Acts:</span>
              <span className="value">{renderCountValue('Acts')}</span>
            </div>
            <div className="metadata-pair-count">
              <span className="key">Regulations:</span>
              <span className="value">{renderCountValue('Regulations')}</span>
            </div>
            <div className="metadata-pair-count">
              <span className="key">Rules:</span>
              <span className="value">{renderCountValue('Rules')}</span>
            </div>
            </div>
          </div>

          <div className="section">
            <h4>Latest Circular</h4>
            <div className="metadata-pair">
              <span className="key">Latest Circular Name:</span>
              <span className="value">{renderLatestValue('Latest Circular')}</span> 
            </div>
            <div className="metadata-pair">
              <span className="key">Latest Circular Date:</span>
              <span className="value">{formatDate(renderLatestValue('Latest Circular Date'))}</span> 
            </div>
          </div>
        </div>
        </div>
      );
    }

    return rows;
  };

  

  return (

    <div>
      {isErrorModalOpen && (
        <div className="modal" id="errorModal">
          <div className="modal-content">
            <span className="close" onClick={closeModal}>&times;</span>
            <p id="errorMessage">{errorMessage}</p>
          </div>
        </div>
      )}
      <div className={`chat-container ${isDarkMode ? 'dark-mode' : 'light-mode'} ${isSavingChat ? 'saving' : ''}`}>

        {isMobileMenuOpen && (
          <MobileMenu
            chatHistory={chatHistory}
            isOpen={isMobileMenuOpen}
            toggleMenu={toggleMobileMenu}
            handleChatHistoryClick={handleChatHistoryClick}
            activeChatIndex={activeChatIndex}
            handleDeleteChat={handleDeleteChat}
          />
        )}
        <div className="body-container">
          <div className='sidebar-container'>
            <div className='complieaze-logo'>
              {/* <a href="https://complieaze.ai" target="_blank" rel="noopener noreferrer"> */}
              <div className="logo">
                <span style={{ color: "#3F9BA1" }}>Compli</span>
                <span style={{ color: "#000" }}>eaze</span>
              </div>
              {/* </a> */}
              <h2 className="titlee">Regulatory compliance assistant</h2>
            </div>
            <div className={`sidebar ${isDarkMode ? 'dark-mode' : 'light-mode'}`}>
              {!isDarkMode ? (
                <>
                  <div className="chat-history">
                    {chatHistory.map((chat, index) => (
                      <div
                        key={index}
                        className={`chat-history-entry ${index === activeChatIndex ? 'active' : ''}`}
                        onClick={() => handleChatHistoryClick(index)}>
                        {chat.name}

                        {index === activeChatIndex && (
                          <button className="delete-button" onClick={(event) => handleDeleteChat(index, event)}>
                            <FontAwesomeIcon icon={faTrash} />
                          </button>
                        )}
                      </div>
                    ))}
                  </div>
                  <button id="newchat" className="new-chat-button" onClick={handleNewChat}>
                    + New Chat
                  </button>
                  <div className="toggle-switch-container">
        <span className={isDarkMode ? '' : 'selected'}>Prompt</span>
        <div className="toggle-switch" onClick={toggleDarkMode}>
          <div className={`toggle-button ${isDarkMode ? 'browse' : 'prompt'}`}></div>
        </div>
        <span className={isDarkMode ? 'selected' : ''}>Browse</span>
      </div>

                </>
              ) : (
                <>
                  <div className="chat-history">
          {chatHistory.map((chat, index) => (
            <div
              key={index}
              className={`chat-history-entry ${index === activeChatIndex ? 'active' : ''}`}
              onClick={() => handleChatHistoryClick(index)}>
              {chat.name}
              {index === activeChatIndex && (
                <button className="delete-button" onClick={(event) => handleDeleteChat(index, event)}>
                  <FontAwesomeIcon icon={faTrash} />
                </button>
              )}
            </div>
          ))}
        </div>
        <button id="newchat" className="new-chat-button" onClick={handleNewChat}>
          + New Chat
        </button>
        <div className="toggle-switch-container">
          <span className={isDarkMode ? '' : 'selected'}>Prompt</span>
          <div className="toggle-switch" onClick={toggleDarkMode}>
            <div className={`toggle-button ${isDarkMode ? 'browse' : 'prompt'}`}></div>
          </div>
          <span className={isDarkMode ? 'selected' : ''}>Browse</span>
        </div>
      </>
    )}
  </div>

  <div className='dataeaze-logo'>
    product by <img src="/dataease-logo.png" className="footer-image-dataeaze" alt="User" />
  </div>
          </div>
          <Modal isOpen={modalOpen} onClose={() => setModalOpen(false)} data={modalData} />
          <div className={`chat-content ${isDarkMode ? 'dark-mode' : 'light-mode'} ${isSavingChat ? 'saving' : ''} ${isDarkMode ? 'chat-content-browse' : 'chat-content'}`}>
            {!isDarkMode ? (
              <>
                <div className='header-content'>
                  <div className="header-text">
                    {renderMetadataRows()}
                  </div>
                  <button className="regulator-button" variant="outlined" onClick={handleToggleForm}>
                    Select Regulator
                  </button>
                  {showRegulatorForm && (
                    <div className="form-containerrr">
                      <IconButton className="close-button" onClick={handleCloseForm}>
                        <CloseIcon />
                      </IconButton>
                      <div className="form-group">
                        <FormControl
                          variant="outlined"
                          size="small"
                          className={`dropdown-control ${isDropdownOpen ? 'dropdown-open' : ''}`}
                          style={{ minWidth: 160, marginTop: 10 }}
                        >
                          <InputLabel className="custom-regulator-label">Regulator</InputLabel>
                          <Select
                multiple
                value={selectedRegulator}
                onChange={handleRegulatorChange}
                onClose={() => setIsDropdownOpen(false)}
                onOpen={() => setIsDropdownOpen(true)}
                input={
                  <OutlinedInput
                    label="Regulator"
                    className="custom-regulator-input"
                    style={{ fontFamily: 'Manrope, sans-serif !important' }}
                  />
                }
                renderValue={(selected) => selected.join(', ')}
              >
                {regulators.map((regulator, index) => (
                      <MenuItem
                        key={index} 
                        value={regulator.name} 
                        style={{ fontFamily: 'Manrope, sans-serif !important' }}
                      >
                        <Checkbox checked={selectedRegulator.indexOf(regulator.name) > -1} />
                        <ListItemText
                          primary={regulator.name} 
                          style={{ fontFamily: 'Manrope, sans-serif !important' }}
                        />
                      </MenuItem>
                    ))}
              </Select>
                        </FormControl>
                      </div>
                      <button className="regulator-submit-button" variant="contained" color="primary" onClick={handleSubmit}>
                        Submit
                      </button>
                      {isFormLoading && (
                        <div className="form-loader-container">
                          <div className="formloader"></div>
                        </div>
                      )}
                    </div>
                  )}
                  <div className="user-profile-container">
                    <button id="profile-btn" className="user-profile-button" type="button" onClick={toggleUserProfileDropdown}>
                      <div className="user-profile-image">
                        <img src={process.env.PUBLIC_URL + '/account_circle.png'} alt="User Profile" />
                      </div>
                    </button>
                    {isUserProfileDropdownOpen && (
                      <div className="user-profile-dropdown">
                        <div onClick={() => handleUserOptionClick('Logout')}>Logout</div>
                        <div onClick={() => handleUserOptionClick('Generate Token')}>Generate Token</div>
                        {isSuperAdmin && (
                          <div onClick={() => handleUserOptionClick('Admin')}>Admin</div>
                        )}

                        {/* <div onClick={() => toggleDarkMode('prompt')}>Prompt</div>
                        <div onClick={() => toggleDarkMode('browse')}>Browse</div> */}
                      </div>
                    )}
                  </div>

                </div>
                <div className='chat-details'>
                  <div className={`chat-placeholder ${chatPlaceholder ? 'visible' : ''}`}>
                    {chatPlaceholder}
                  </div>
                  <div className="chat-messages" ref={chatContainerRef}>
                  <div className="chat-history-response">
  {responseData && responseData.messages && responseData.messages.length > 0 ? (
    responseData.messages.map((messageGroup, groupIndex) => (
      <div key={groupIndex}>
        {messageGroup.chat.map((message, index) => (
          <div key={index} className={`message ${message.isUser ? 'user-message' : 'bot-message'}`}>
            <div className={`message-content ${message.isUser ? 'user-content' : 'bot-content'}`}>
              {message.isUser ? (
                <div className="user-icon">
                  <img src="/account_circle.png" alt="User" />
                </div>
              ) : (
                <div className="bot-icon">
                  <img src="bot-removebg-preview.png" alt="Bot" />
                </div>
              )}
              <div className={`message-text ${message.isUser ? 'user-text' : 'bot-text'}`}>
                {typeof message.text === 'string' ? (
                  message.text
                ) : message.text?.type === 'span' ? (
                  <div dangerouslySetInnerHTML={{ __html: message.text.props.children[1].props.children }} />
                ) : (
                  // Safely extract and render text if it is a nested object
                  JSON.stringify(message.text)
                )}
              </div>
            </div>
            {!message.isUser && <hr className={`message-divider ${message.isUser ? 'user-divider' : 'bot-divider'}`} />}
          </div>
        ))}
      </div>
    ))
  ) : (
    <div></div>
  )}
</div>


                    {messages.map((message, index) => (
                      <div
                        key={index}
                        className={`message ${message.isUser ? 'user-message' : 'bot-message'}`}
                      >
                        <div className={`message-content ${message.isUser ? 'user-content' : 'bot-content'}`}>
                          {message.isUser ? (
                            <div className="user-icon">
                              <img src="/account_circle.png" alt="User" />
                            </div>
                          ) : (
                            <div className="bot-icon">
                              <img src="bot-removebg-preview.png" alt="User" />
                            </div>
                          )}
                          <div className={`message-text ${message.isUser ? 'user-text' : 'bot-text'}`}>
                            {message.isUser ? (
                              message.text
                            ) : (
                              <>
                                <div className="bot-response">
                                  {message.text}
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                        {message.isUser ? null : <hr className={`message-divider ${message.isUser ? 'user-divider' : 'bot-divider'}`} />}
                      </div>
                    ))}
                    <div ref={scrollToBottom}></div>
                  </div>
                  {isLoading && (
                    <div className="loader-container">
                      <div className="loader"></div>
                    </div>
                  )}
                  <div className="input-box-container">
                    <div className='input-content'>   
                    {!(regulator === "RBI" || regulator === "SEBI") && (
                              <>
                                <input
                                  type="file"
                                  id="file-input"
                                  style={{ display: 'none' }}
                                  multiple
                                />
                                <FontAwesomeIcon 
                                  icon={faPaperclip} 
                                  className="attachment-icon" 
                                  onClick={handleIconClick} 
                                />
                              </>
                            )}
                      <input
                        ref={inputRef}
                        type="text"
                        value={inputText}
                        onChange={handleInputChange}
                        onKeyDown={handleInputKeyDown}
                        placeholder="Enter your query"
                        className="input-box"
                        disabled={isLoading} 
                      />
                      <PopupForm 
                        isOpen={isPopupOpen} 
                        onClose={handleClosePopup} 
                        onSubmit={handleFormSubmit} 
                      />
                      <div className={`suggestions-container ${suggestionsVisible ? 'visible' : ''}`}>
                        {suggestionsVisible && (
                          <ul className="suggestions-list">
                            {suggestions.map((suggestion, index) => (
                              <li
                                key={index}
                                className={`suggestion-item ${index === selectedSuggestionIndex ? 'selected-suggestion' : ''}`}
                                onClick={() => handleSuggestionClick(suggestion)}
                              >
                                {suggestion}
                              </li>
                            ))}
                          </ul>
                        )}
                      </div>
                      <button id="autoclick" onClick={() => handleSendMessage()} className="send-button">
                        <div className="send-button-image">
                          <img src={process.env.PUBLIC_URL + '/white_arrow.png'} alt="User Profile" />
                        </div>
                      </button>
                    </div>
                    <button className="clear-chat-button" onClick={() => { setResponseData([]); setMessages([]); setChatPlaceholder(placeholderText) }}>
                      Clear Chat
                    </button>
                  </div>
                </div>

              </>
            ) : (
              <>
              <div className='header-content'>
                <div className="browse-header-text">
                  {renderMetadataRows()}
                </div>
                <button className="browse-regulator-button" variant="outlined" onClick={handleToggleForm}>
                    Select Regulator
                  </button>
                  {showRegulatorForm && (
                    <div className="form-containerrr">
                      <IconButton className="close-button" onClick={handleCloseForm}>
                        <CloseIcon />
                      </IconButton>
                      <div className="form-group">
                        <FormControl
                          variant="outlined"
                          size="small"
                          className={`dropdown-control ${isDropdownOpen ? 'dropdown-open' : ''}`}
                          style={{ minWidth: 160, marginTop: 10 }}
                        >
                          <InputLabel className="custom-regulator-label">Regulator</InputLabel>
                          <Select
                multiple
                value={selectedRegulator}
                onChange={handleRegulatorChange}
                onClose={() => setIsDropdownOpen(false)}
                onOpen={() => setIsDropdownOpen(true)}
                input={
                  <OutlinedInput
                    label="Regulator"
                    className="custom-regulator-input"
                    style={{ fontFamily: 'Manrope, sans-serif !important' }}
                  />
                }
                renderValue={(selected) => selected.join(', ')}
              >
                {regulators.map((regulator, index) => (
                      <MenuItem
                        key={index} 
                        value={regulator.name} 
                        style={{ fontFamily: 'Manrope, sans-serif !important' }}
                      >
                        <Checkbox checked={selectedRegulator.indexOf(regulator.name) > -1} />
                        <ListItemText
                          primary={regulator.name} 
                          style={{ fontFamily: 'Manrope, sans-serif !important' }}
                        />
                      </MenuItem>
                    ))}
              </Select>
                        </FormControl>
                      </div>
                      <button className="regulator-submit-button" variant="contained" color="primary" onClick={handleSubmit}>
                        Submit
                      </button>
                      {isFormLoading && (
                        <div className="form-loader-container">
                          <div className="formloader"></div>
                        </div>
                      )}
                    </div>
                  )}
                <div className="user-profile-container">
                    <button id="profile-btn" className="user-profile-button" type="button" onClick={toggleUserProfileDropdown}>
                      <div className="user-profile-image">
                        <img src={process.env.PUBLIC_URL + '/account_circle.png'} alt="User Profile" />
                      </div>
                    </button>
                    {isUserProfileDropdownOpen && (
                      <div className="user-profile-dropdown">
                        <div onClick={() => handleUserOptionClick('Logout')}>Logout</div>
                        <div onClick={() => handleUserOptionClick('Generate Token')}>Generate Token</div>
                        {isSuperAdmin && (
                          <div onClick={() => handleUserOptionClick('Admin')}>Admin</div>
                        )}
                        {/* <div onClick={() => toggleDarkMode('prompt')}>Prompt</div>
                        <div onClick={() => toggleDarkMode('browse')}>Browse</div> */}
                      </div>
                    )}
                  </div>
                 </div>
                {/* <hr className="browse-divider" /> */}
                <div className="browse-mode-content">
                  <div className="dropdown-container">
                    <div className='content-dropdown'>
                      {/* <select className="browse-dropdown" onChange={handleFirstDropdownChange} value={selectedTopic}>
                        <option value="">Select Topic</option>
                        <option value="master_circular">Tax</option>
                        <option value="circulars">Banking</option>
                        <option value="master_direction"> Payment</option>
                      </select> */}
                      <img src="/arrow-icon.png" alt="arrow Icon" className="arrow-icon" />
                    </div>
                    <label className="filter-label">Select timeframe for circulars to search</label>
                    <div className="date-picker-container">
                      <div className="date-picker-wrapper">
                        <DatePicker
                          selected={startDate}
                          onChange={handleStartDateChange}
                          selectsStart
                          startDate={startDate}
                          endDate={endDate}
                          dateFormat="dd/MM/yyyy"
                          placeholderText="Start Date"
                          className="date-picker"
                        />
                        <img src="/calendar-icon.png" alt="Calendar Icon" className="calendar-icon" />
                      </div>
                      <div className="date-picker-wrapper">
                        <DatePicker
                          selected={endDate}
                          onChange={handleEndDateChange}
                          selectsEnd
                          startDate={startDate}
                          endDate={endDate}
                          minDate={startDate}
                          dateFormat="dd/MM/yyyy"
                          placeholderText="End Date"
                          className="date-picker"
                        />
                        <img src="/calendar-icon.png" alt="Calendar Icon" className="calendar-icon" />
                      </div>
                    </div>
                    <label className="filter-label">Type of search</label>
                    <div className="dropdown-container1">
                      <select className="browsee-dropdown" onChange={handleSecondDropdownChange} value={selectedOption}>
                        <option value="Context">Content Based Search</option>
                        <option value="Title">Title of Circular</option>
                        <option value="Circular Number">Circular Number</option>
                      </select>
                      <img src="/arrow-icon.png" alt="arrow Icon" className="arrow-iconn" />
                      <input
                        type="text"
                        placeholder="Enter your input"
                        className="inputt-box"
                        value={browseInputText}
                        onChange={handleBrowseInputChange}
                      />
                    </div>
                    <div className='browse_buttons'>
                      <button onClick={handleClearSearch} className="clear-search-button">
                        Clear Search
                      </button>
                      {regulator !== 'RBI' && regulator !== 'SEBI' &&(
                        <button onClick={handleIconClick} className="upload-button">
                          Upload
                        </button>
                      )}
                      <button onClick={handleBrowseSubmit} className="submitt-button">
                        Submit
                      </button>
                      <input
                        type="file"
                        id="file-input"
                        style={{ display: 'none' }}
                        multiple
                      />

                      <PopupForm
                        isOpen={isPopupOpen}
                        onClose={handleClosePopup}
                        onSubmit={handleFormSubmit}
                      />
                    </div>
                  </div>

                  {/* <button onClick={() => setViewMode("graph")} className="graph-button">
        Show Similarity Graph
      </button> */}
                  {isBrowseLoading && (
                    <div className="browse-loader-container">
                      <div className="loader"></div>
                    </div>
                  )}
                  {!isBrowseLoading && noDataMessage && (
                    <div className="no-data-message">{noDataMessage}</div>
                  )}
                  {viewMode === "list" && apiResponse.length > 0 && (
                    <div className="table-container1">
                      <div style={{ height: 485, width: "1086px" }}>
                        <DataGrid
                          rows={currentData}
                          columns={apiResponseColumns
                            .filter(column => column) 
                            .map((column) =>  {
                            if (column.field === 'circularNumber') {
                              return {
                                ...column,
                                renderCell: (params) => (
                                  <span onClick={() => handleCircularNumberClick(params.value)}
                                    style={{ cursor: 'pointer', color: 'blue' }}
                                  >
                                    {params.value}
                                  </span>
                                ),
                              };
                            }
                            return column;
                          })}
                          pageSize={rowsPerPage}
                          pagination={true}
                          rowsPerPageOptions={[10, 20, 50, 100]}
                          rowCount={totalDataCount}
                          getRowId={(row) => row.serialNumber}
                          onPageChange={(params) => setCurrentPage(params.page)}
                          onPageSizeChange={(params) => setRowsPerPage(params.pageSize)}
                          // onRowClick={(params) => { }}
                          components={{
                            Toolbar: () => (
                              <div style={{ padding: "10px" }}>
                                <GridToolbarExport />
                              </div>
                            ),
                          }}
                        />
                      </div>
                    </div>
                  )}
                  {viewMode === "graph" && apiResponse.length > 0 && (
                    <div>
                      <div className="toggle-button-container">
                        <span className="toggle-label">2D</span>
                        <div className="toggle-button-wrapper">
                          <button
                            className={`toggle-button ${dimension === "2D" ? "toggle-left" : "toggle-right"}`}
                            onClick={toggleDimension}
                          >
                            <span className="toggle-button-slider"></span>
                          </button>
                        </div>
                        <span className="toggle-label">3D</span>
                      </div>
                      <div className="graph-container">
                        {dimension === "2D" ? (
                          <Plot
                            data={[
                              {
                                type: "scatter",
                                mode: "markers",
                                x: graphData.embeddings2D.map((point) => point[0]),
                                y: graphData.embeddings2D.map((point) => point[1]),
                                text: apiResponse.map((doc) => doc.title),
                                marker: { size: 4 },
                              },
                            ]}
                            layout={graphLayout2D}
                            style={{ width: "300px", height: "250px" }}
                            className="plotly-graph"
                          />
                        ) : (
                          <Plot
                            data={[
                              {
                                type: "scatter3d",
                                mode: "markers",
                                x: graphData.embeddings3D.map((point) => point[0]),
                                y: graphData.embeddings3D.map((point) => point[1]),
                                z: graphData.embeddings3D.map((point) => point[2]),
                                text: apiResponse.map((doc) => doc.title),
                                marker: { size: 4 },
                              },
                            ]}
                            layout={graphLayout3D}
                            style={{ width: "300px", height: "250px" }}
                            className="plotly-graph"
                          />
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </>
            )}
          </div>
        </div>

      </div>
      {isSavingChat && (
        <div className="loader-containerr">
          <div className="loaderr"></div>
        </div>
      )}
 <ToastContainer position="top-center" autoClose={5000} hideProgressBar={false} newestOnTop={true} closeOnClick pauseOnHover draggable />
    </div>

  );

};
export default ChatUI;