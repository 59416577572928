import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faInfoCircle } from '@fortawesome/free-solid-svg-icons'; 
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './PopupForm.css';
import axios from 'axios';  

const PopupForm = ({ isOpen, onClose, onSubmit }) => {
  const [subject, setSubject] = useState('');
  const [date, setDate] = useState('');
  const [file, setFile] = useState(null);
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const [errors, setErrors] = useState({ subject: false, file: false });
  const [tooltipDateVisible, setTooltipDateVisible] = useState(false); 
  const [tooltipFileVisible, setTooltipFileVisible] = useState(false);
  const [tooltipRegulatorVisible, setTooltipRegulatorVisible] = useState(false);
  const [regulators, setRegulators] = useState([]); 
  const [selectedRegulator, setSelectedRegulator] = useState(''); 
  
  useEffect(() => {
    const fetchRegulators = async () => {
      try {
        const response = await axios.get('/rbac/permitted_regulators'); 
        setRegulators(response.data.regulators);
      } catch (error) {
        console.error('Error fetching regulators', error);
      }
    };
    
    fetchRegulators();
  }, []);
  
  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setErrors({ ...errors, file: false, fileType: false });

    if (selectedFile) {
      const fileType = selectedFile.type;
      if (fileType !== 'application/pdf') {
        setFile(null); 
        setErrors({ ...errors, fileType: true }); 
      } else {
        setFile(selectedFile); 
      }
    } else {
      setFile(null); 
    }
  };
  
  const handleDateChange = (selectedDate) => {
    setDate(selectedDate);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
  
    const newErrors = {
      subject: subject.trim() === '',
      file: file === null,
      regulator: selectedRegulator === '',
    };
  
    setErrors(newErrors);
    if (newErrors.subject || newErrors.file || newErrors.regulator) {
      return;
    }
  
    // Manually format the date in dd-mm-yyyy format
    const formattedDate = date
      ? `${String(date.getDate()).padStart(2, '0')}-${String(date.getMonth() + 1).padStart(2, '0')}-${date.getFullYear()}`
      : '';
  
    const formData = new FormData();
    formData.append('subject', subject);
    formData.append('date', formattedDate);  // Updated date format
    formData.append('regulator', selectedRegulator);
    formData.append('files', file);
    
    console.log(formData);
    onSubmit(formData);
  
    // Reset the form fields
    setSubject('');
    setDate('');
    setFile(null);
    setSelectedRegulator('');
    onClose();
  };
  

  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="popup-modal-content">
        <h2>Upload Document</h2>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label className="subject-label">
              Subject <span className="required-asterisk">*</span>
              <FontAwesomeIcon
                icon={faInfoCircle}
                className="info-icon"
                onMouseEnter={() => setTooltipVisible(true)}
                onMouseLeave={() => setTooltipVisible(false)}
              />
              {tooltipVisible && (
                <div className="tooltip">Unique subject name that can be used to retrieve the same document later</div>
              )}
            </label>
            <input
              type="text"
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
              required
              className={`input-field ${errors.subject ? 'input-error' : ''}`}
            />
            {errors.subject && <span className="error-text">Subject is required.</span>}
          </div>

          <div className="form-group">
            <label className="regulator-label">
              Regulator <span className="required-asterisk">*</span>
              <FontAwesomeIcon
                icon={faInfoCircle}
                className="info-icon"
                onMouseEnter={() => setTooltipRegulatorVisible(true)}
                onMouseLeave={() => setTooltipRegulatorVisible(false)}
              />
              {tooltipRegulatorVisible && (
                <div className="regulator-tooltip">Please select the appropriate regulator for uploading the document.</div>
              )}
            </label>
            <select
              value={selectedRegulator}
              onChange={(e) => setSelectedRegulator(e.target.value)}
              className={`input-field ${errors.regulator ? 'input-error' : ''}`}
              required
            >
              <option value="">-- Select Regulator --</option>
              {regulators.map((regulator, index) => (
                <option key={index} value={regulator}>
                  {regulator}
                </option>
              ))}
            </select>
            {errors.regulator && <span className="error-text">Regulator is required.</span>}
          </div>

          {/* Date Picker Field */}
          <div className="form-group">
            <label className="date-label">
              Date of Circular
              <FontAwesomeIcon
                icon={faInfoCircle}
                className="info-icon"
                onMouseEnter={() => setTooltipDateVisible(true)}
                onMouseLeave={() => setTooltipDateVisible(false)}
              />
              {tooltipDateVisible && (
                <div className="date-tooltip">Enter the circular date in the format dd-mm-yyyy</div>
              )}
            </label>
            <DatePicker
              selected={date}
              onChange={handleDateChange}
              dateFormat="dd-MM-yyyy"
              placeholderText="dd-mm-yyyy"
              className={`input-field`}
            />
          </div>

          {/* File Upload Field */}
          <div className="form-group">
            <label className="upload-file-label">
              Select Document <span className="required-asterisk">*</span>
              <FontAwesomeIcon
                icon={faInfoCircle}
                className="info-icon"
                onMouseEnter={() => setTooltipFileVisible(true)}
                onMouseLeave={() => setTooltipFileVisible(false)}
              />
              {tooltipFileVisible && (
                <div className="file-tooltip">Select the document file you wish to upload</div>
              )}
            </label>
            <input
              type="file"
              accept=".pdf" 
              onChange={handleFileChange}
              required
              className={`input-field ${errors.file ? 'input-error' : ''}`}
            />
            {file && <span className="selected-file-text">Selected File: {file.name}</span>}
            {errors.file && <span className="error-text">File is required.</span>}
            {errors.fileType && <span className="error-text">Only PDF files are allowed.</span>}

          </div>

          <button type="submit" className="popup-submit-button">
            Upload
          </button>
        </form>
        <FontAwesomeIcon
          icon={faTimes}
          className="close-icon"
          onClick={onClose}
        />
      </div>
    </div>
  );
};

export default PopupForm;
